import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const Desglose = (props) => {
    const [data, setdata] = useState([]);
    const [totales, setTotales] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        const endpoint = "https://erp.biowater.mx/endpoint/orden"
        const json = { "operation": "productos", "api_key": props.apiKey, "order_id": props.orderId }
        const json2 = { "operation": "totales", "api_key": props.apiKey, "order_id": props.orderId }
        Axios.post(endpoint, json)
            .then(res => {
                setdata(res.data);
            })
        Axios.post(endpoint, json2)
            .then(res => {
                setTotales(res.data);
            })
    }, [props.apiKey, props.orderId]);


    return (
        <div>
            <TableContainer id={'tabla_desglose'} component={Paper} className="mb-3">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Producto</StyledTableCell>
                            <StyledTableCell align="center">Modelo</StyledTableCell>
                            <StyledTableCell align="center">Cantidad</StyledTableCell>
                            <StyledTableCell align="right">Precio unitario</StyledTableCell>
                            <StyledTableCell align="right">Total</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                    {Object.values(row.options).map((item, index) => (
                                        <small key={index}>
                                            <br />
                                            {item.name}: {item.value}
                                        </small>
                                    )
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.model}</StyledTableCell>
                                <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                                <StyledTableCell align="right">{row.price}</StyledTableCell>
                                <StyledTableCell align="right">{row.total}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {totales.map((row) => (
                            <StyledTableRow key={row.value}>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                                <StyledTableCell align="right">{row.title}</StyledTableCell>
                                <StyledTableCell align="right">{row.value}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Desglose
