import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import MaterialTable from 'material-table';
import Skeleton from '@material-ui/lab/Skeleton';
import Cookies from 'universal-cookie';

const Garantias = (props) => {
    const [data, setData] = useState(false);
    const [skeleton, setSkeleton] = useState(true);
    const endpoint = `${props.host}/garantia`;
    const cookie = new Cookies();
    const userLevel = cookie.get('level');


    const FetchData = useCallback(() => {
        const json = { "operation": "lista", "api_key": props.apiKey };
        setSkeleton(true);
        Axios.post(endpoint, json)
            .then(res => {
                setData(res.data);
            })
    }, [endpoint, props.apiKey]);

    useEffect(() => {
        FetchData();
    }, [FetchData]);

    useEffect(() => {
        !data && setSkeleton(true);
        data && setSkeleton(false);
    }, [data]);


    const desactivar = (warranty_id) => {
        let confirm = window.confirm('¿Estás seguro que deseas desactivar esta garantía? Esto no se puede deshacer...')
        if (confirm) {
            manejarGarantia('desactivar', warranty_id, null, null);
        }

    }
    const activar = (warranty_id, serie, email) => {
        manejarGarantia('activar', warranty_id, serie, email);
    }

    const manejarGarantia = (operation, warranty_id, serie, email) => {
        const json = { "operation": operation, "api_key": props.apiKey, "warranty_id": warranty_id, "serie": serie, "email": email }
        Axios.post(endpoint, json)
            .then(res => {
                FetchData();
            })
    }

    return (

        <div>
            {skeleton &&
                <>
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />

                </>
            }
            {!skeleton &&
                <div className="mb-3">
                    <MaterialTable
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}',
                                labelRowsSelect: 'Filas'
                            },
                            toolbar: {
                                nRowsSelected: '{0} filas(s) seleccionadas',
                                searchPlaceholder: 'Buscar'
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            body: {
                                emptyDataSourceMessage: 'No hay resultados que mostrar',
                                filterRow: {
                                    filterTooltip: 'Filtrar'
                                }
                            }
                        }}
                        // other props
                        title="Garantías"
                        columns={[
                            { title: "Serie", field: "serial_number" },
                            { title: "Nombre", field: "name" },
                            { title: "Telefóno", field: "phone" },
                            { title: "Email", field: "email" },
                            { title: "Ciudad", field: "city" },
                            { title: "Estado", field: "zone" },
                            { title: "Fecha de instalación", field: "date_added" },
                            { title: "Status", field: "warranty_state" },
                        ]}
                        actions={[
                            rowData => ({
                                icon: 'thumb_up',
                                tooltip: 'Aprobar',
                                onClick: (event, rowData) => activar(rowData.warranty_id, rowData.serial_number, rowData.email),
                                disabled: rowData.warranty_state !== 'Pendiente' || userLevel !== 'admin'
                            }),
                            rowData => ({
                                icon: 'block',
                                tooltip: 'Desactivar',
                                onClick: (event, rowData) => desactivar(rowData.warranty_id, rowData.serial_number, rowData.email),
                                disabled: userLevel !== 'admin'
                            })

                        ]}
                        options={{
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            pageSize: 10,
                            actionsColumnIndex: -1
                        }}
                        data={data}

                        detailPanel={[
                            {
                                tooltip: 'Detalles',
                                render: rowData => {
                                    return (
                                        <div>
                                            <ul style={{ listStyle: 'none' }}>
                                                <li><b style={{ width: '230px', display: 'inline-block' }}>Producto:</b> {rowData.product}</li>
                                                <li><b style={{ width: '230px', display: 'inline-block' }}>Modelo:</b> {rowData.model}</li>
                                                <li><b style={{ width: '230px', display: 'inline-block' }}>ID de garantía:</b> {rowData.warranty_id}</li>
                                                <li><b style={{ width: '230px', display: 'inline-block' }}>Distribuidor a cargo:</b> {rowData.distribuidor}</li>
                                                <li><b style={{ width: '230px', display: 'inline-block' }}>Orden relacionada:</b> {rowData.order_id}</li>
                                                <li><b style={{ width: '230px', display: 'inline-block' }}>Fecha de la Orden relacionada:</b> {rowData.order_date}</li>
                                            </ul>

                                        </div>
                                    )
                                },
                            }
                        ]}
                    />
                </div>

            }

        </div>
    )
}

export default Garantias
