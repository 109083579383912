import React from 'react'
import Logo from '../../img/logo.svg'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'

const Header = (props) => {
    const history = useHistory();
    const cookie = new Cookies()
    const logOut = () => {
        cookie.remove('token')
        cookie.remove('name')
        cookie.remove('level')
        window.location.href = "/"
    }
    return (
        <header className="mb-4" style={{ borderBottom: 'solid 1px #f1f1f1' }}>
            <div className="mt-3" style={{ textAlign: 'right' }}><p className="m-0 p-0">{cookie.get('name')} <span onClick={logOut} style={{ fontSize: 'x-small', cursor: 'pointer' }}>(Cerrar sesión)</span></p></div>
            <Navbar bg='bg-red' expand="lg">
                <Navbar.Brand href="#" onClick={() => history.push('/')}><img style={{ maxWidth: '250px' }} src={Logo} alt="logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link onClick={() => history.push('/distribuidores')} href="#">Distribuidores</Nav.Link>
                        <Nav.Link onClick={() => history.push('/garantias')} href="#">Garantías</Nav.Link>
                        <Nav.Link onClick={() => history.push('/ordenes')} href="#">Ordenes</Nav.Link>
                        <Nav.Link onClick={() => history.push('/reportes')}>Reportes</Nav.Link>
                        <NavDropdown title="Facturación" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={() => history.push('/facturacion/facturas')} >Facturas</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => history.push('/facturacion/clientes')}>Clientes</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Productos" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={() => history.push('/productos/catalogo')} >Catalogo</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => history.push('/productos/lista_precios')}  >Lista de precios</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => history.push('/productos/inventario')} >Inventario</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    )
}

export default Header