import React, { useCallback, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import Cookies from 'universal-cookie';
import FormattedNumber from '../assets/formattedNumber';
import Dropdown from 'react-bootstrap/Dropdown';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import CustomAlert from '../assets/customAlert';
import LoadingScreen from '../assets/loadingScreen';
import { Button, Dialog, DialogTitle, MenuItem, Select, InputLabel, FormControl, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Modal from 'react-bootstrap/Modal';
import { setDate } from 'date-fns';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LoadingBlock from '../assets/loadingBlock';


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    }
});


const FacturasTable = (props) => {
    const cookies = new Cookies();
    const userLevel = cookies.get('level');
    const endpoint = `${props.host}/facturas`;
    const [json, setJson] = useState(false);
    const [customAlert, setCustomAlert] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);
    const classes = useStyles();
    const [cancellationDialog, setCancellationDialog] = useState(false);
    const [cancellationMotive, setCancellationMotive] = useState(false);
    const [relatedUUID, setRelatedUUID] = useState(null);
    const [invoiceToCancel, setInvoiceToCancel] = useState(null);
    const [loadingBlock, setLoadingBlock] = useState(false);

    //For complements
    const [paymentDate, setPaymentdate] = useState(new Date());
    const [facturapiInvoice, setFacturapiInvoice] = useState(false);
    const [orderId, setOrderId] = useState(0);
    const [uuid, setUuid] = useState(0);
    const [invoiceId, setInvoiceId] = useState(0);
    const [paymentForm, setPaymentForm] = useState(0);

    //Modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //End Modal

    const handleDocumentDownload = (id, fileType) => {
        Axios.post(endpoint, {
            "operation": "download",
            "api_key": props.apiKey,
            "invoice_id": id,
            "file_type": fileType
        })
            .then(res => {
                res.data.success ? fileDownload(Buffer.from(res.data.success, 'base64'), `${id}.${fileType}`) : alert(res.data);
            });

    }

    const handleCancel = (id) => {
        setCancellationDialog(true);
        setInvoiceToCancel(id);
    }
    const cancelInvoice = () => {
        window.confirm("¿Estás seguro que deseas cancelar esta factura?") &&
            setLoadingScreen(true);
        setLoadingBlock(true);

        let substitution;
        if (relatedUUID === null) substitution = null;
        else substitution = relatedUUID;


        Axios.post(endpoint, {
            "operation": "cancel",
            "api_key": props.apiKey,
            "invoice_id": invoiceToCancel,
            "motive": cancellationMotive,
            "substitution": substitution
        }).then(res => {
            setLoadingScreen(false);
            setLoadingBlock(false);
            setCancellationDialog(false);

            if (res.data.message) {
                setCustomAlert({
                    'title': 'Error al cancelar la factura',
                    'severity': 'error',
                    'message1': res.data.message
                });
            } else {
                setCustomAlert({
                    'title': 'Se canceló la factura correctamente',
                    'severity': 'success',
                    'message1': res.data.uuid
                });
                setTimeout(() => {
                    props.reloadTable();
                }, 3000);
            }
        });
    }

    const handleAddComplement = (id, orderId, uuid) => {
        setOrderId(orderId);
        setUuid(uuid);
        setInvoiceId(id);
        setJson({
            "operation": "request_facturapi_invoice",
            "api_key": props.apiKey,
            "valid_invoice_id": id,
            "valid_uuid": uuid,
            "order_id": orderId,
            "paymentdate": paymentDate
        });
    }

    const handleAddComplementSend = () => {
        handleClose();
        setJson({
            "operation": "add_complement",
            "api_key": props.apiKey,
            "valid_invoice_id": invoiceId,
            "valid_uuid": uuid,
            "order_id": orderId,
            "payment_date": paymentDate,
            "facturapi_invoice": facturapiInvoice,
            "payment_form": paymentForm
        });
    }
    const handlePaymentDate = (date) => {
        setPaymentdate(date);
    }

    const closeAlert = useCallback(() => {
        setCustomAlert(false);
    }, []);

    useEffect(() => {
        json && json.operation === 'request_facturapi_invoice' &&
            Axios.post(endpoint, json)
                .then(res => {
                    setPaymentdate(res.data.payment_date);
                    setFacturapiInvoice(res.data.invoice);
                    setShow(true);
                    setJson(false);
                });

        json && json.operation === 'add_complement' &&
            Axios.post(endpoint, json)
                .then(res => {
                    if (res.data.message) {
                        setCustomAlert({
                            'title': 'Error al crear complemento',
                            'severity': 'error',
                            'message1': res.data.message
                        });
                    } else {
                        setCustomAlert({
                            'title': 'Se creo el complemento correctamente',
                            'severity': 'success',
                            'message1': res.data.uuid
                        })
                    }
                });

        json && json.operation === 'cancel' &&
            Axios.post(endpoint, json)
                .then(res => {
                    if (res.data.message) {
                        setCustomAlert({
                            'title': 'Error al cancelar la factura',
                            'severity': 'error',
                            'message1': res.data.message
                        });
                    } else {
                        setCustomAlert({
                            'title': 'Se canceló la factura correctamente',
                            'severity': 'success',
                            'message1': res.data.uuid
                        })
                    }
                });
    }, [json, endpoint, props]);

    useEffect(() => {
        customAlert && setJson(false);
    }, [customAlert]);

    useEffect(() => {
        json && setLoadingScreen(true);
        !json && setLoadingScreen(false);
    }, [json]);

    useEffect(() => {
        if (!cancellationDialog) {
            setCancellationMotive(null);
            setInvoiceToCancel(null);
            setRelatedUUID(null);
        }
    }, [cancellationDialog])

    return (
        <>
            {loadingScreen &&
                <LoadingScreen />
            }

            {
                customAlert &&
                <CustomAlert
                    title={customAlert.title}
                    severity={customAlert.severity}
                    message1={customAlert.message1}
                    closeAlert={closeAlert}
                />
            }

            <Dialog open={cancellationDialog}>
                {loadingBlock &&
                    <LoadingBlock />
                }

                <DialogTitle>Motivo de cancelación</DialogTitle>
                <FormControl className={classes.formControl} style={{ margin: '1rem' }}>
                    <InputLabel id="relacion-label">Motivo</InputLabel>
                    <Select value={cancellationMotive} onChange={(e) => setCancellationMotive(e.target.value)} name="relation_type_code" labelId="relacion-label" id="relacion" >
                        <MenuItem value="01">Comprobantes emitidos con errores con relación.</MenuItem>
                        <MenuItem value="02">Comprobantes emitidos con errores sin relación.</MenuItem>
                        <MenuItem value="03">No se llevó a cabo la operación.</MenuItem>
                        <MenuItem value="04">Operación nominativa relacionada en una factura global.</MenuItem>
                    </Select>
                </FormControl>
                {cancellationMotive && cancellationMotive === '01' &&
                    <>
                        <FormControl className={classes.formControl} style={{ margin: '1rem' }}>
                            <TextField onChange={(e) => setRelatedUUID(e.target.value)} label="uuid relacionado" name="uuid" />
                        </FormControl>
                    </>

                }


                <div style={{ margin: '1rem', display: 'flex' }}>
                    <Button onClick={() => setCancellationDialog(false)} style={{ maxWidth: '150px', marginRight: '1rem' }} color="default" variant='contained'>Cerrar</Button>
                    <Button onClick={() => cancelInvoice()} style={{ maxWidth: '150px' }} color="primary" variant='contained'>Continuar</Button>
                </div>

            </Dialog>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar fecha de pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl className={classes.formControl} style={{ margin: '1rem' }}>
                        <InputLabel id="payment-form">Forma de pago</InputLabel>
                        <Select value={paymentForm} onChange={(e) => setPaymentForm(e.target.value)} name="payment_form_code" labelId="payment-form-label" id="payment_form" >
                            <MenuItem value="01">	Efectivo</MenuItem>
                            <MenuItem value="02">	Cheque nominativo</MenuItem>
                            <MenuItem value="03">	Transferencia electrónica de fondos</MenuItem>
                            <MenuItem value="04">	Tarjeta de crédito</MenuItem>
                            <MenuItem value="05">	Monedero electrónico</MenuItem>
                            <MenuItem value="06">	Dinero electrónico</MenuItem>
                            <MenuItem value="08">	Vales de despensa</MenuItem>
                            <MenuItem value="12">	Dación en pago</MenuItem>
                            <MenuItem value="13">	Pago por subrogación</MenuItem>
                            <MenuItem value="14">	Pago por consignación</MenuItem>
                            <MenuItem value="15">	Condonación</MenuItem>
                            <MenuItem value="17">	Compensación</MenuItem>
                            <MenuItem value="23">	Novación</MenuItem>
                            <MenuItem value="24">	Confusión</MenuItem>
                            <MenuItem value="25">	Remisión de deuda</MenuItem>
                            <MenuItem value="26">	Prescripción o caducidad</MenuItem>
                            <MenuItem value="27">	A satisfacción del acreedor</MenuItem>
                            <MenuItem value="28">	Tarjeta de débito</MenuItem>
                            <MenuItem value="29">	Tarjeta de servicios</MenuItem>
                            <MenuItem value="30">	Aplicación de anticipos</MenuItem>
                            <MenuItem value="31">	Intermediario pagos</MenuItem>
                            <MenuItem value="99">	Por definir</MenuItem>
                        </Select>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                autoOk="true"
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Fecha de pago"
                                value={paymentDate}
                                onChange={handlePaymentDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl><br />

                </Modal.Body>
                <Modal.Footer>
                    <Button className="mr-3" variant="contained" color="inactive" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleAddComplementSend}>
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="mb-3">
                <MaterialTable
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'Filas'
                        },
                        toolbar: {
                            nRowsSelected: '{0} filas(s) seleccionadas',
                            searchPlaceholder: 'Buscar'
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay resultados que mostrar',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            }
                        }
                    }}
                    // other props
                    title={props.title}
                    columns={[
                        { title: "Factura", field: "order_id" },
                        { title: "Nombre", field: "firstname" },
                        {
                            title: "Estado",
                            lookup: {
                                "Activa": "Activa",
                                "Cancelada": "Cancelada"
                            },
                            field: "status",
                            render: rowData => {
                                const status = rowData.status
                                let style
                                switch (status) {
                                    case "Activa":
                                        style = { background: 'green', color: 'white', borderRadius: '5px' }
                                        break
                                    case "Cancelada":
                                        style = { background: 'red', color: 'white', borderRadius: '5px' }
                                        break
                                    default:
                                        style = { style }
                                }
                                return <div className="p-2" style={style}>{rowData.status}</div>

                            }
                        },
                        { title: "RFC", field: "tax_id" },
                        { title: "Razón Social", field: "legal_name" },
                        {
                            title: "Total", field: "total", render: (rowData) => (
                                <FormattedNumber value={rowData.total} />
                            )
                        },
                        { title: "Fecha de emisión", field: "fecha", defaultSort: 'desc' },
                        {
                            title: "", field: "", render: (rowData) => {
                                return (
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" size='sm'>
                                            Acciones
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item key="pdf_download" onClick={() => handleDocumentDownload(rowData.id_fact, 'pdf')}>Descargar PDF</Dropdown.Item>
                                            <Dropdown.Item key="xml_download" onClick={() => handleDocumentDownload(rowData.id_fact, 'xml')}>Descargar XML</Dropdown.Item>

                                            {userLevel === 'admin' &&
                                                <>
                                                    <Dropdown.Item onClick={() => handleAddComplement(rowData.id_fact, rowData.order_id, rowData.uuid)}>Agregar complemento</Dropdown.Item>
                                                    < Dropdown.Divider />
                                                    <Dropdown.Item onClick={() => handleCancel(rowData.id_fact)}>Cancelar</Dropdown.Item>
                                                </>
                                            }

                                        </Dropdown.Menu>
                                    </Dropdown>
                                )

                            }
                        }

                    ]}
                    options={{
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: '#245c80',
                            color: '#FFF'
                        },
                        actionsColumnIndex: -1,
                        filtering: true
                    }}
                    data={props.data}
                />
            </div >

        </>

    )
}

export default FacturasTable