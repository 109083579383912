import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { PostAdd } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { setStoreOrderData } from '../../store/actions/mercadolibre/storeOrder';
import { useHistory } from 'react-router-dom';

const MercadolibreTable = (props) => {
    const history = useHistory();
    const [orderInStore, setOrderInStore] = useState(false);
    const dispatch = useDispatch();
    const storeOrderData = {
        "store_order": {
            "order": props.storeOrder,
            "store_order_json": props.storeOrderJson
        },
        "mercadolibre_stats": props.data.stats
    }
    dispatch(setStoreOrderData(storeOrderData));

    useEffect(() => {
        props.data.order_exists_in_store && setOrderInStore(props.data.order_exists_in_store[0].order_id);
        !props.data.order_exists_in_store && setOrderInStore('Aun no disponible');
    }, [props.data.order_exists_in_store]);
    return (
        <div className="mb-3" >
            <MaterialTable
                // other props
                title="Ventas en Mercadolibre"
                columns={[
                    { title: "Orden en tienda", field: "order_in_store" },
                    { title: "Mes", field: "month" },
                    {
                        title: "Ventas", field: "sales", headerStyle: {
                            backgroundColor: '#419cd4'
                        }
                    },
                    {
                        title: "Total", field: "total_amount", headerStyle: {
                            backgroundColor: '#419cd4'
                        }
                    },
                    {
                        title: "Ventas incluyendo facturadas", field: "sales_with_taxed", headerStyle: {
                            backgroundColor: '#17a2b8'
                        }
                    },
                    {
                        title: "Total incluyendo facturadas", field: "total_with_taxed", headerStyle: {
                            backgroundColor: '#17a2b8'
                        }
                    }
                ]}
                actions={
                    [
                        rowData => ({
                            icon: PostAdd,
                            tooltip: 'Crear nueva orden',
                            disabled: rowData.order_in_store !== 'Aun no disponible',
                            onClick: () => history.push('/ml_store_order')
                        })
                    ]
                }
                options={{
                    pageSize: 1,
                    headerStyle: {
                        backgroundColor: '#245c80',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    paging: false,
                    search: false
                }}
                data={[{ order_in_store: orderInStore, month: props.data.month, sales: props.data.stats.orders, total_amount: props.data.stats.total_amount, sales_with_taxed: props.data.sales_with_tax_documented_orders, total_with_taxed: props.data.total_with_tax_documented_orders }]}
            />
        </div >
    )
}

export default MercadolibreTable