import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Series from './series';
import ListGroup from 'react-bootstrap/ListGroup';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Cookies from 'universal-cookie';


const Producto = ({ match, host }) => {
    const [data, setData] = useState([]);
    const [skeleton, setSkeleton] = useState(true);
    const cookie = new Cookies();
    const apiKey = cookie.get('token');

    useEffect(() => {
        const endpoint = `${host}/producto`;
        const json = { "operation": "detalles", "api_key": apiKey, "product_id": match.params.product_id };
        Axios.post(endpoint, json)
            .then(res => {
                setData(res.data);
                setSkeleton(false);
            })
    }, [host, apiKey, match.params.product_id]);

    return (

        <div>
            {skeleton &&
                <>
                    <Skeleton animation="wave" variant="circle" width={80} height={80} />
                    <Skeleton animation="wave" height={50} />
                    <Skeleton animation="wave" height={50} />
                    <Skeleton animation="wave" height={50} />


                </>
            }
            {!skeleton &&
                data.map((item, index) => (
                    <Paper key={index} className="p-3 mb-3">
                        <div className="row mb-3">
                            <div className="col-12 col-md-2">
                                <img src={"https://distribuidores.biowater.mx/image/" + item.image} style={{ maxWidth: '144px', objectFit: 'contain', maxHeight: '144px', display: 'block', margin: '0 auto' }} alt="producto" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-6">
                                <ListGroup>
                                    <ListGroup.Item><b>{item.name}</b></ListGroup.Item>
                                    <ListGroup.Item><span className="text-secondary">Modelo: </span><span>{item.model}</span></ListGroup.Item>
                                    <ListGroup.Item><span className="text-secondary">Id de producto: </span><span>{item.product_id}</span></ListGroup.Item>
                                </ListGroup>
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <ListGroup>
                                    <ListGroup.Item><span className="text-secondary">Meses de garantía: </span><span>{item.warranty_months}</span></ListGroup.Item>
                                    <ListGroup.Item><span className="text-secondary">Estado del producto en la tienda: </span><span>{item.status}</span></ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>

                    </Paper>
                ))
            }

            {< Series apiKey={apiKey} productId={match.params.product_id} />}


        </div>
    )
}

export default Producto
