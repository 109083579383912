import React from "react";
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
const style = {
    marginBottom: '1rem',
    position: 'fixed',
    zIndex: '99',
    width: '80%',
    left: '0',
    right: '0',
    margin: 'auto'
}
const CustomAlert = (props) => {
    return (
        <Alert
            elevation={2}
            style={style}
            severity={props.severity}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        props.closeAlert();
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
        >
            <AlertTitle>{props.title}</AlertTitle>
            <div><strong>{props.message1}</strong></div>
        </Alert>
    )
}
export default CustomAlert