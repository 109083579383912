import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MaterialTable from 'material-table';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import FormattedNumber from '../assets/formattedNumber';

const Reportes = (props) => {
    const [monthYear, setMonthYear] = useState(new Date());
    const [publico, setPublico] = useState(false);
    const [distribuidores, setDistribuidores] = useState(false);
    const [globalTotals, setGlobalTotals] = useState(false);
    const [viewDetails, setViewDetails] = useState({
        checkedA: true,
        checkedB: false,
    });
    const [skeleton, setSkeleton] = useState(true);

    const FetchData = useCallback((operation) => {
        let endpoint = `${props.host}/reportes`;
        let json = {
            'operation': operation,
            'api_key': props.apiKey,
            'month': monthYear.getMonth() + 1,
            'year': monthYear.getFullYear()
        }
        setSkeleton(true);
        Axios.post(endpoint, json)
            .then(res => {
                operation === 'publico' && setPublico(res.data);
                operation === 'totals' && setGlobalTotals(res.data);
                operation === 'distribuidores' && setDistribuidores(res.data);
            });
    }, [monthYear, props.apiKey, props.host]);

    const handleDateChange = (date) => {
        date.getMonth() !== monthYear.getMonth() && setMonthYear(date);
    };

    const handleViewDetails = (event) => {
        setViewDetails({ ...viewDetails, [event.target.name]: event.target.checked });
    }

    useEffect(() => {
        FetchData('publico');
        FetchData('distribuidores');
        FetchData('totals');
    }, [FetchData]);

    useEffect(() => {
        publico && distribuidores && globalTotals && setSkeleton(false);
    }, [publico, distribuidores, globalTotals]);

    return (

        <div>
            <FormGroup row style={{ justifyContent: 'center' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale} >
                    <KeyboardDatePicker
                        autoOk="true"
                        disableToolbar
                        variant="inline"
                        format="MMMM - yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Mes y año"
                        value={monthYear}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        views={['year', 'month']}
                        style={{ marginBottom: '1rem' }}
                    />
                </MuiPickersUtilsProvider>
                <FormControlLabel
                    style={{ marginTop: '1rem', marginLeft: '1rem' }}
                    control={
                        <Switch
                            checked={viewDetails.checkedB}
                            onChange={handleViewDetails}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Ver detalles"
                />
            </FormGroup>
            {skeleton &&

                <>
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />

                </>
            }
            {!skeleton &&
                <>
                    <MaterialTable
                        style={{ marginBottom: '1rem' }}
                        title={'Público'}
                        columns={[
                            { title: "", field: "Category" },
                            { title: "Pedidos", field: "Pedidos" },
                            { title: "Clientes", field: "Clientes" },
                            {
                                title: "Productos", field: "Productos", render: (rowData) => {
                                    if (rowData.Productos === null) rowData.Productos = 0;
                                    return rowData.Productos;

                                }
                            },
                            {
                                title: "Subtotal", field: "Subtotal", render: (rowData) => {
                                    if (rowData.Subtotal !== null) return <FormattedNumber value={rowData.Subtotal} />;
                                    if (rowData.Subtotal === null) return "$0.00";
                                }
                            },
                            {
                                title: "Impuestos", field: "Impuestos", render: (rowData) => {
                                    if (rowData.Impuestos !== null) return <FormattedNumber value={rowData.Impuestos} />;
                                    if (rowData.Impuestos === null) return "$0.00";
                                }
                            },
                            {
                                title: "Envío", field: "Envío", render: (rowData) => {
                                    if (rowData.Envío !== null) return <FormattedNumber value={rowData.Envío} />;
                                    if (rowData.Envío === null) return "$0.00";
                                }
                            },
                            {
                                title: "Total", field: "Total", render: (rowData) => {
                                    if (rowData.Total !== null) return <FormattedNumber value={rowData.Total} />;
                                    if (rowData.Total === null) return "$0.00";
                                }
                            }

                        ]}
                        options={{
                            pageSize: 4,
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            actionsColumnIndex: -1,
                            filtering: false,
                            search: false,
                            paging: false,
                            exportButton: true,
                            exportFileName: 'Publico',
                            rowStyle: rowData => ({
                                backgroundColor: (rowData.tableData.id === 2) ? '#f0f9ff' : '',
                                color: (rowData.tableData.id === 2) ? '#06436a' : ''
                            })
                        }}
                        data={publico.totals}
                    />
                    <MaterialTable
                        style={{ marginBottom: '1rem' }}
                        title={'Distribuidores'}
                        columns={[
                            { title: "", field: "Category" },
                            { title: "Pedidos", field: "Pedidos" },
                            { title: "Clientes", field: "Clientes" },
                            {
                                title: "Productos", field: "Productos", render: (rowData) => {
                                    if (rowData.Productos === null) rowData.Productos = 0;
                                    return rowData.Productos;
                                }
                            },
                            {
                                title: "Subtotal", field: "Subtotal", render: (rowData) => {
                                    if (rowData.Subtotal !== null) return <FormattedNumber value={rowData.Subtotal} />;
                                    if (rowData.Subtotal === null) return "$0.00";
                                }
                            },
                            {
                                title: "Impuestos", field: "Impuestos", render: (rowData) => {
                                    if (rowData.Impuestos !== null) return <FormattedNumber value={rowData.Impuestos} />;
                                    if (rowData.Impuestos === null) return "$0.00";
                                }
                            },
                            {
                                title: "Envío", field: "Envío", render: (rowData) => {
                                    if (rowData.Envío !== null) return <FormattedNumber value={rowData.Envío} />;
                                    if (rowData.Envío === null) return "$0.00";
                                }
                            },
                            {
                                title: "Total", field: "Total", render: (rowData) => {
                                    if (rowData.Total !== null) return <FormattedNumber value={rowData.Total} />;
                                    if (rowData.Total === null) return "$0.00";
                                }
                            }

                        ]}
                        options={{
                            pageSize: 4,
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            actionsColumnIndex: -1,
                            filtering: false,
                            search: false,
                            paging: false,
                            exportButton: true,
                            exportFileName: 'Distribuidores',
                            rowStyle: rowData => ({
                                backgroundColor: (rowData.tableData.id === 3) ? '#f0f9ff' : '',
                                color: (rowData.tableData.id === 3) ? '#06436a' : ''
                            })
                        }}
                        data={distribuidores.totals}
                    />

                    <h4 style={{ backgroundColor: '#f0f9ff', color: '#245c80', padding: '1rem', fontWeight: '700' }}>Subtotal: <NumberFormat value={globalTotals.Subtotal} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} /> </h4>
                    <h4 style={{ backgroundColor: '#245c80', color: 'white', padding: '1rem', fontWeight: '700' }}>Total: <NumberFormat value={globalTotals.Total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} /> </h4>
                </>
            }
            {viewDetails.checkedB &&
                <>
                    <MaterialTable
                        style={{ marginBottom: '1rem' }}
                        title={'Público Detalles'}
                        columns={[
                            { title: "Orden", field: "Orden" },
                            { title: "Fecha", field: "Fecha" },
                            { title: "Nombre", field: "Nombre" },
                            { title: "Modelo", field: "Modelo" },
                            { title: "Producto", field: "Producto" },
                            { title: "Precio", field: "Precio" },
                            { title: "Cantidad", field: "Cantidad" },
                            { title: "Subtotal", field: "Subtotal" },
                            { title: "Iva", field: "IVA" },
                            { title: "Total", field: "Total" },
                            { title: "Estado", field: "Estado" }

                        ]}
                        options={{
                            pageSizeOptions: [5, 10, 20, publico.details.length],
                            pageSize: 10,
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            actionsColumnIndex: -1,
                            filtering: false,
                            search: true,
                            paging: true,
                            exportButton: true,
                            exportFileName: 'Público Detalles'
                        }}
                        data={publico.details}
                    />

                    <MaterialTable
                        style={{ marginBottom: '1rem' }}
                        title={'Distribuidores Detalles'}
                        columns={[
                            { title: "Orden", field: "Orden" },
                            { title: "Fecha", field: "Fecha" },
                            { title: "Nombre", field: "Nombre" },
                            { title: "Modelo", field: "Modelo" },
                            { title: "Producto", field: "Producto" },
                            { title: "Precio", field: "Precio" },
                            { title: "Cantidad", field: "Cantidad" },
                            { title: "Subtotal", field: "Subtotal" },
                            { title: "Iva", field: "IVA" },
                            { title: "Total", field: "Total" },
                            { title: "Estado", field: "Estado" }

                        ]}
                        options={{
                            pageSizeOptions: [5, 10, 20, distribuidores.details.length],
                            pageSize: 10,
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            actionsColumnIndex: -1,
                            filtering: false,
                            search: true,
                            paging: true,
                            exportButton: true,
                            exportFileName: 'Distribuidores Detalles'
                        }}
                        data={distribuidores.details}
                    />
                    <MaterialTable
                        style={{ marginBottom: '1rem' }}
                        title={'Público Productos'}
                        columns={[
                            { title: "Cantidad", field: "Cantidad" },
                            { title: "Producto", field: "Producto" },
                            { title: "Subtotal", field: "Subtotal" }

                        ]}
                        options={{
                            pageSizeOptions: [5, 10, 20, publico.products.length],
                            pageSize: 10,
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            actionsColumnIndex: -1,
                            filtering: false,
                            search: true,
                            paging: true,
                            exportButton: true,
                            exportFileName: 'Publico Productos'
                        }}
                        data={publico.products}
                    />
                    <MaterialTable
                        style={{ marginBottom: '1rem' }}
                        title={'Distribuidores Productos'}
                        columns={[
                            { title: "Cantidad", field: "Cantidad" },
                            { title: "Producto", field: "Producto" },
                            { title: "Subtotal", field: "Subtotal" }

                        ]}
                        options={{
                            pageSizeOptions: [5, 10, 20, distribuidores.products.length],
                            pageSize: 10,
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            actionsColumnIndex: -1,
                            filtering: false,
                            search: true,
                            paging: true,
                            exportButton: true,
                            exportFileName: 'Distribuidores Productos'
                        }}
                        data={distribuidores.products}
                    />
                </>
            }

        </div >

    )
}

export default Reportes