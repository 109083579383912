import React, { useState, useEffect, useCallback } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import FacturasTable from './facturasTable';
import FormattedNumber from '../assets/formattedNumber';
import { Paper, Button } from '@material-ui/core';
import NoEncontradasTable from './noEncontradasTable';


const Facturas = (props) => {
    const date = new Date();
    const [data, setData] = useState(false);
    const [selectedFrom, setSelectedFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [selectedTo, setSelectedTo] = useState(new Date());
    const [skeleton, setSkeleton] = useState(true);
    const [facturasTableTitle, setOrdenesTableTitle] = useState('Este mes');
    const endpoint = `${props.host}/facturas`;



    const handleDateFrom = (date) => {
        setSelectedFrom(date);
    }

    const handleDateTo = (date) => {
        setSelectedTo(date);
    }

    const FilterDate = useCallback(() => {
        setSkeleton(true);
        Axios.post(endpoint, {
            "operation": "all",
            "api_key": props.apiKey,
            "date_from": selectedFrom.getFullYear() + '-' + (selectedFrom.getMonth() + 1) + '-' + selectedFrom.getDate() + 'T00:00:00',
            "date_to": selectedTo.getFullYear() + '-' + (selectedTo.getMonth() + 1) + '-' + selectedTo.getDate() + 'T23:59:59'
        }).then(res => {
            setData(res.data);
        })
    }, [selectedFrom, selectedTo])

    //Skeleton loading
    useEffect(() => {
        if (!data) {
            setSkeleton(true);
        } else {
            setSkeleton(false);
        }
    }, [data]);

    //Fetch data
    useEffect(() => {
        FilterDate();
        setOrdenesTableTitle('Este Mes');
    }, []);


    return (
        <div>



            <div className="row mb-3" style={{ alignItems: 'center' }}>
                <div className="col-md-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk="true"
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Desde"
                            value={selectedFrom}
                            onChange={handleDateFrom}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />

                    </MuiPickersUtilsProvider>
                </div>
                <div className="col-md-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk="true"
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Hasta"
                            value={selectedTo}
                            onChange={handleDateTo}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />

                    </MuiPickersUtilsProvider>

                </div>
                <div className="col-md-2">
                    <Button variant="contained" color="primary" onClick={() => { FilterDate(); setOrdenesTableTitle('Filtrado por fecha') }} >Filtrar</Button>
                </div>
            </div>

            {skeleton &&
                <>
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />

                </>
            }
            {!skeleton &&
                <>
                    < FacturasTable data={data.invoices} title={facturasTableTitle} host={props.host} apiKey={props.apiKey} reloadTable={FilterDate} />
                    <h5 style={{ padding: '1rem', textAlign: 'center' }}>Se encontraron <b>{data.resume.invoices}</b> facturas activas, por un total de <b><FormattedNumber value={data.resume.total} /></b></h5>
                    <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
                        <h4 style={{ marginBottom: '1rem' }}>Notas de cotejación</h4>
                        <div>Total en notas facturadas: <b><FormattedNumber value={data.comparison.total_taxed_orders} /></b></div>
                        <div>Total en tienda: <b><FormattedNumber value={data.comparison.total_store_orders} /></b></div>
                        <div>Diferencia: <b><FormattedNumber value={data.comparison.difference} /></b></div>
                    </Paper>
                    <div style={{ width: '49%', float: 'left' }}>
                        <h5 style={{ marginBottom: '1rem' }}>Las siguientes facturas se encontraron en facturación pero no en la tienda:</h5>
                        <NoEncontradasTable data={data.comparison.invoices_not_in_store} />
                    </div>
                    <div style={{ width: '49%', float: 'right' }}>
                        <h5 style={{ marginBottom: '1rem' }}>Las siguientes ordenes se encontraron en la tienda pero no en facturación:</h5>
                        <NoEncontradasTable data={data.comparison.orders_without_invoice} />
                    </div>



                </>

            }


        </div>
    )
}

export default Facturas