import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import MaterialTable from 'material-table';
import Skeleton from '@material-ui/lab/Skeleton';

const Inventario = (props) => {
    const [data, setdata] = useState([]);
    const [skeleton, useSkeleton] = useState(true);

    useEffect(() => {
        const endpoint = `${props.host}/inventario`;
        const json = { "operation": "lista", "api_key": props.apiKey };
        Axios.post(endpoint, json)
            .then(res => {
                setdata(res.data);
                HideSkeleton();
            })
    }, [props.host, props.apiKey])

    const HideSkeleton = () => {
        useSkeleton(false)
    }

    return (
        <div>
            {skeleton &&
                <>
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />

                </>
            }
            {!skeleton &&
                <div className="mb-3">
                    <MaterialTable
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}',
                                labelRowsSelect: 'Filas'
                            },
                            toolbar: {
                                nRowsSelected: '{0} filas(s) seleccionadas',
                                searchPlaceholder: 'Buscar'
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            body: {
                                emptyDataSourceMessage: 'No hay resultados que mostrar',
                                filterRow: {
                                    filterTooltip: 'Filtrar'
                                }
                            }
                        }}
                        // other props
                        title="Inventario (producto sin armar)"
                        columns={[
                            { title: 'Modelo', field: "nombre" },
                            { title: "Descripción", field: "modelo" },
                            { title: "Categoría", field: "categoria" },
                            { title: "Cantidad", field: "cantidad" },
                            { title: "Ubicación", field: "ubicacion" },

                        ]}
                        options={{
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            pageSize: 10,
                            actionsColumnIndex: -1
                        }}
                        data={data}
                    />
                </div>
            }

        </div>
    )
}

export default Inventario
