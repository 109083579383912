import React, { useState, createContext } from 'react'
import './App.css'
import Header from './components/header/header'
import Orden from './components/ordenes/orden'
import Distribuidores from './components/distribuidores/distribuidores'
import Productos from './components/productos/catalogo'
import Producto from './components/productos/producto'
import Ordenes from './components/ordenes/ordenes'
import Inventario from './components/inventario/inventario'
import Garantias from './components/garantias/garantias'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Login from './components/login/login'
import Cookies from 'universal-cookie'
import MercadolibreStoreOrder from './components/mercadolibre/mercadolibreStoreOrder';
import Reportes from './components/reportes/reportes'
import ClientesFacturacion from './components/facturacion/clientesFacturacion';
import Facturas from './components/facturacion/facturas';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Facturar from './components/facturacion/facturar';
import ListaPrecios from './components/productos/listaPrecios';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#245c80'
    },
    secondary: {
      main: '#419cd4'
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      containedPrimary: {
        // Some CSS
        backgroundColor: '#245c80',
        '&:hover': {
          backgroundColor: '#0b3752'
        }
      },
    },
  },
});

export const OptionsContext = createContext();

function App(props) {
  const cookies = new Cookies()
  const apiKey = cookies.get('token')
  const host = "https://erp.biowater.mx/endpoint";
  const [storeForInvoice, setStorerForInvoice] = useState(null);
  const [options, setOptions] = useState({
    'showInvoicePendingOrders': false,
    'ordersFrom': false,
    'ordersTo': false
  });

  const handleStoreOrderForInvoice = (storeUrl) => {
    setStorerForInvoice(storeUrl);
  }

  return (
    <OptionsContext.Provider value={[options, setOptions]}>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="container" style={{ maxWidth: '1100px' }}>
            <div className="row">
              <div className="col-12">

                <Switch>
                  {!apiKey && <Route path="/" component={Login} />}
                  {apiKey &&
                    <>
                      <Header />
                      <Route path="/" exact>
                        <Ordenes apiKey={apiKey} host={host} setStoreForInvoice={handleStoreOrderForInvoice} />
                      </Route>
                      <Route path="/distribuidores" >
                        <Distribuidores apiKey={apiKey} host={host} />
                      </Route>
                      <Route path="/productos/catalogo/producto/:product_id" render={(props) => <Producto {...props} apiKey={apiKey} host={host} />} />
                      <Route path="/ordenes" exact>
                        <Ordenes apiKey={apiKey} host={host} setStoreForInvoice={handleStoreOrderForInvoice} />
                      </Route>
                      <Route path="/garantias">
                        <Garantias apiKey={apiKey} host={host} />
                      </Route>
                      <Route path="/productos/inventario" render={(props) => <Inventario {...props} apiKey={apiKey} host={host} />} />
                      <Route path="/orden/:order_id" render={(props) => <Orden {...props} host={host} />}>
                      </Route>
                      <Route path="/productos/lista_precios" render={(props) => <ListaPrecios {...props} apiKey={apiKey} host={host} />} exact />
                      <Route path="/productos/catalogo" exact>
                        <Productos apiKey={apiKey} host={host} />
                      </Route>
                      <Route path="/ml_store_order" component={MercadolibreStoreOrder} />
                      <Route path="/reportes" render={(props) => <Reportes {...props} host={host} apiKey={apiKey} />} />
                      <Route path="/facturacion/clientes">
                        <ClientesFacturacion host={host} apiKey={apiKey} />
                      </Route>
                      <Route path="/facturacion/facturas" exact>
                        <Facturas apiKey={apiKey} host={host} />
                      </Route>
                      <Route path="/facturacion/facturar/:order_id" render={(props) => <Facturar {...props} host={host} apiKey={apiKey} storeUrl={storeForInvoice} />}>
                      </Route>
                    </>
                  }
                </Switch>
              </div>
            </div>
          </div>
        </Router>
      </ThemeProvider>
    </OptionsContext.Provider>



  );
}

export default App;
