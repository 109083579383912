import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import MaterialTable from 'material-table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import TagImage from './../../img/code.jpg'
import Skeleton from '@material-ui/lab/Skeleton'

const Series = (props) => {
    const [data, setdata] = useState([])
    const [serie, setSerie] = useState([])
    const [skeleton, useSkeleton] = useState(true)

    //Estados del modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const FetchData = () => {
        const endpoint = "https://erp.biowater.mx/endpoint/producto"
        const json = { "operation": "series", "api_key": props.apiKey, 'product_id': props.productId }
        Axios.post(endpoint, json)
            .then(res => {
                setdata(res.data)
                HideSkeleton()
            })

    }

    const FetchSerie = () => {
        const endpoint = "https://erp.biowater.mx/endpoint/producto"
        const json = { "operation": "siguiente_serie", "api_key": props.apiKey, 'product_id': props.productId }
        Axios.post(endpoint, json)
            .then(res => {
                setSerie(res.data)
            })

    }

    const agregarSerie = () => {
        const endpoint = "https://erp.biowater.mx/endpoint/producto"
        const json = { "operation": "agregar_serie", "api_key": props.apiKey, 'product_id': props.productId, "serial_number": serie.next_serial_number }
        Axios.post(endpoint, json)
            .then(res => {
                handleShow()
            })

    }

    const serieConfirmada = () => {
        setShow(false)
        FetchData()
        FetchSerie()
    }

    const HideSkeleton = () => {
        useSkeleton(false)
    }

    useEffect(() => {
        FetchData()
        FetchSerie()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

        <div>
            {skeleton &&
                <>
                    <Skeleton variant="rect" height={60} />
                    <br />
                    <Skeleton variant="rect" height={60} />
                    <br />
                    <Skeleton variant="rect" height={60} />
                    <br />
                    <Skeleton variant="rect" height={60} />
                    <br />
                    <Skeleton variant="rect" height={60} />
                    <br />
                    <Skeleton variant="rect" height={60} />
                    <br />
                    <Skeleton variant="rect" height={60} />
                    <br />
                    <Skeleton variant="rect" height={60} />
                    <br />
                    <Skeleton variant="rect" height={60} />

                </>
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Número de serie agregado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-3">
                            <img style={{ maxWidth: '100%', display: 'block', margin: '0 auto' }} src={TagImage} alt="" />
                        </div>
                        <div className="col-9">
                            <div className="alert alert-success" role="alert">
                                Se agregó el número de serie correctamente
                            </div>
                            <p>A continuación busca la etiqueta con la serie:</p>
                            <b className="mb-3" style={{ display: 'inline-block', fontSize: '1.5rem', border: 'solid 1px green', padding: '5px' }}>{serie.next_serial_number}</b>
                            <p>Pégala en el producto y colocalo en <b>Producto terminado</b></p>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={serieConfirmada}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <div className="mb-3">
                <MaterialTable
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'Filas'
                        },
                        toolbar: {
                            nRowsSelected: '{0} filas(s) seleccionadas',
                            searchPlaceholder: 'Buscar'
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay resultados que mostrar',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            }
                        }
                    }}
                    // other props
                    title="Números de serie"
                    columns={[
                        { title: "Número de serie", field: "serial_number" },
                        { title: "Ubicación", field: "location" },
                        { title: "Orden relacionada", field: "order_id" },
                        { title: "Distribuidor a cargo", field: "distribuidor" },
                        { title: "Cliente final", field: "cliente" },
                        { title: "Garantía", field: "garantia" }

                    ]}
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Agregar serie',
                            isFreeAction: true,
                            onClick: (event) => agregarSerie()
                        }
                    ]}
                    options={{
                        pageSize: 5,
                        headerStyle: {
                            backgroundColor: '#245c80',
                            color: '#FFF'
                        },
                        actionsColumnIndex: -1
                    }}
                    data={data}
                />
            </div>
        </div>
    )
}

export default Series
