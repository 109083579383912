import React from 'react';
import MaterialTable from 'material-table';
import { useHistory } from "react-router-dom";
import Cookies from 'universal-cookie';
import { Alert } from '@material-ui/lab';


const OrdenesTable = (props) => {
    const history = useHistory();
    const cookies = new Cookies();
    const userLevel = cookies.get('level');
    const handleClick = (order_id) => {
        history.push(`/orden/${order_id}`)
    };
    let defaultFilter = [];

    if (props.defaultFilter) {
        defaultFilter = ['Enviado', 'Pagado - Procesando'];
    }

    return (
        <div className="mb-3">
            <MaterialTable
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'Filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} filas(s) seleccionadas',
                        searchPlaceholder: 'Buscar'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay resultados que mostrar',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
                // other props
                title={props.title}
                columns={[
                    { title: "Número de orden", field: "order_id" },
                    { title: "Nombre", field: "name" },
                    {
                        defaultSort: 'desc',
                        title: "Estado tienda",
                        lookup: {
                            "Pendiente de pago": "Pendiente de pago",
                            "Pagado - Procesando": "Pagado - Procesando",
                            "Facturado": "Facturado",
                            "Cancelado": "Cancelado",
                            "Cerrado Dolares": "Cerrado Dolares",
                            "Cerrado Interior": "Cerrado Interior",
                            "Cerrado mostrador": "Cerrado mostrador",
                            "Enviado": "Enviado"
                        },
                        defaultFilter: defaultFilter,
                        field: "order_status",
                        render: rowData => {
                            const order_status_id = rowData.order_status_id
                            let style
                            switch (order_status_id) {
                                case "1":
                                    style = { background: '#f23b3b', color: 'white', borderRadius: '5px' }
                                    break
                                case "17":
                                    style = { background: '#f23b3b', color: 'white', borderRadius: '5px' }
                                    break
                                case "18":
                                    style = { background: '#ffc800', color: '#71111a', borderRadius: '5px' }
                                    break
                                case "19":
                                    style = { background: '#28a745', color: 'white', borderRadius: '5px' }
                                    break
                                case "20":
                                    style = { background: '#245c80', color: 'white', borderRadius: '5px' }
                                    break
                                case "21":
                                    style = { background: '#333', color: 'white', borderRadius: '5px' }
                                    break
                                case "22":
                                    style = { background: '#00b7ff', color: '002c58', borderRadius: '5px' }
                                    break
                                case "23":
                                    style = { background: '#00b7ff', color: '002c58', borderRadius: '5px' }
                                    break
                                case "25":
                                    style = { background: '#00b7ff', color: '002c58', borderRadius: '5px' }
                                    break
                                case "27":
                                    style = { background: '#ffc800', color: '#71111a', borderRadius: '5px' }
                                    break

                                default:
                                    style = { style }
                            }
                            return <div className="p-2" style={style}>{rowData.order_status}</div>

                        }
                    },
                    { title: "Estado factura", field: "invoice_status" },
                    {
                        title: "Total", field: "total", render: rowData => {
                            return rowData.totals_matches ? rowData.total : < Alert severity='error' > {rowData.total}</Alert>;
                        }
                    },
                    {
                        title: "Total Factura", field: "invoice_total", render: rowData => {
                            return rowData.totals_matches ? rowData.invoice_total : < Alert severity='error' > {rowData.invoice_total}</Alert>;
                        }
                    },
                    { title: "Fecha orden", field: "date_added" }

                ]}
                actions={
                    [
                        rowData => ({
                            icon: 'visibility',
                            tooltip: 'Ver orden',
                            onClick: (event) => handleClick(rowData.order_id)
                        }),
                        rowData => ({
                            icon: 'receipt',
                            tooltip: 'Facturar',
                            onClick: (event) => {
                                props.setStoreForInvoice(rowData.store_url);
                                history.push(`/facturacion/facturar/${rowData.order_id}`);
                            }
                            //,
                            //disabled: rowData.order_status_id === '20' || userLevel !== 'admin'
                        })
                    ]}
                options={{
                    pageSize: 10,
                    headerStyle: {
                        backgroundColor: '#245c80',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    filtering: true
                }}
                data={props.data}
            />
        </div >
    )
}

export default OrdenesTable