import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Axios from 'axios';
import Cookies from 'universal-cookie';


const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {
    const [username, setUserName] = useState(null)
    const [password, setPassword] = useState(null)
    const [loginIncorrect, setLoginIncorrect] = useState(null)

    const classes = useStyles();

    const handleUsername = (e) => {
        setUserName(e.target.value)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }


    const LogIn = (e) => {
        e.preventDefault()
        Axios.post('https://erp.biowater.mx/endpoint/login', { username: username, password: password, "operation": null, "api_key": null })
            .then(res => {
                console.log(res);
                if (res.data.token) {
                    cookies.set('token', res.data.token, { path: '/' })
                    cookies.set('name', res.data.Nombre, { path: '/' })
                    cookies.set('level', res.data.level, { path: '/' })
                    window.location.href = "/"
                }
                else {
                    setLoginIncorrect(true)
                }
            })

    }



    loginIncorrect && cookies.remove('token')

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography style={{ textAlign: 'center' }} component="h1" variant="h5">
                    Plataforma de administracion Biowater
                </Typography>
                <form className={classes.form} noValidate onSubmit={LogIn}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Usuario"
                        name="username"
                        autoFocus
                        onChange={handleUsername}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handlePassword}
                    />
                    {loginIncorrect && <p className="mt-3 mb-0" style={{ color: 'red' }}>Nombre de usuario o contraseña invalidos</p>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Ingresar
                    </Button>

                </form>
            </div>

        </Container>
    );
}