import React, { useState, useEffect, useCallback } from 'react'
import Axios from 'axios'
import PackingBoxItem from '../packingBoxItem/packingBoxItem'
import Skeleton from '@material-ui/lab/Skeleton'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CustomizedSnackbars from './../alerts/CustomizedSnackbars'
import RelatedProducts from '../ordenes/related'


const PackingList = (props) => {
    const [data, setdata] = useState({ boxes: [] })
    const [boxesState, setBoxes] = useState({ totalBoxes: 0, checkedBoxes: 0 })
    const [packingComplete, setPackingComplete] = useState([{ packingComplete: false }])
    const [serials, setSerials] = useState({ serials: [] })
    const [relatedProducts, setRelatedProducts] = useState([])
    const [skeleton, useSkeleton] = useState(true)
    const [alert, setAlert] = useState([])
    const [trackingNumber, setTrackingNumber] = useState("")
    const [comment, setComment] = useState("")
    const [packingListCompleted, setPackingListCompleted] = useState(false)

    useEffect(() => {
        FetchData()
        FetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const FetchData = () => {
        const endpoint = "https://erp.biowater.mx/endpoint/orden"
        const json = { "operation": "packing_list", "api_key": props.apiKey, "order_id": props.orderId }
        Axios.post(endpoint, json)
            .then(res => {
                setdata({ boxes: res.data.boxes })
                setSerials({ serials: res.data.serials })
                setBoxes({ totalBoxes: res.data.boxes.length, checkedBoxes: 0 })
                setRelatedProducts(res.data.related_products)
                HideSkeleton()

            })
    }

    const handleCheckedBoxes = useCallback(
        () => {
            setBoxes({ totalBoxes: boxesState.totalBoxes, checkedBoxes: boxesState.checkedBoxes + 1 })
            confirmComplete()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [boxesState]

    )

    const confirmComplete = () => {
        if (boxesState.totalBoxes === boxesState.checkedBoxes + 1) {
            setPackingComplete({ packingComplete: true })
        }
    }
    const HideSkeleton = () => {
        useSkeleton(false)
    }

    const completePackingList = () => {
        let packing = packingComplete.packingComplete
        let tracking = trackingNumber
        if (packing && tracking !== "") {
            const endpoint = "https://erp.biowater.mx/endpoint/orden"
            const json = { "operation": "complete_packing", "api_key": props.apiKey, "serials": serials.serials, "order_id": props.orderId, "customer_id": props.customer_id, "customer_group_id": props.customer_group_id, "tracking_number": trackingNumber, "email": props.email, "comment": comment }
            Axios.post(endpoint, json)
                .then(res => {
                    console.log(res.data)
                    setPackingListCompleted(true)
                })
        }
        else if (tracking === "") {
            setAlert({ error: true, message: 'No hay ningún número de guía agregado.' })
            setTimeout(() => {
                setAlert([])
            }, 6000);
        }
        else {
            setAlert({ error: true, message: 'No has revisado toda la lista, no puedes completarla todavía.' })
            setTimeout(() => {
                setAlert([])
            }, 6000);
        }


    }

    return (
        <div>
            <Paper className="p-3 mb-3">
                <div className="comments">
                    <h5 className="mb-3">Comentarios del cliente</h5>
                    {props.comments &&
                        <p style={{ padding: '1rem', background: '#245c80', color: 'white', fontWeight: '700' }}>"{props.comments}"</p>
                    }
                    {!props.comments &&
                        <p className="text-secondary">Sin comentarios</p>
                    }
                </div>
            </Paper>


            {skeleton &&
                <div className="row">
                    <div className="col-12 col-md-3" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <Skeleton animation="wave" variant="circle" width={100} height={100} />
                    </div>
                    <div className="col-12 col-md-9">
                        <Skeleton animation="wave" height={50} />
                        <Skeleton animation="wave" height={50} />
                        <Skeleton animation="wave" height={50} />
                    </div>
                </div>
            }
            <Paper className="p-3 mb-3">
                {props.order_status_id === '18' &&
                    <div>
                        <h5>Productos</h5>
                        <hr></hr>
                    </div>
                }

                {props.order_status_id === '18' &&

                    data.boxes.map((item) => {
                        let serialRequired = true
                        let boxState
                        if (item.category_id === '64' || item.product_id === '290' || item.product_id === '296') { serialRequired = false; }
                        if (item.is_complete === false && item.category_id !== '64' && item.customer_group_id !== '1' && item.product_id !== '290' && item.product_id !== '296') { boxState = 'danger' }
                        return (
                            <div key={item.product_id}>
                                <PackingBoxItem data={item} serialRequired={serialRequired} boxState={boxState} handleCheckedBoxes={() => handleCheckedBoxes()} />
                            </div>
                        )
                    })
                }



                {
                    props.order_status_id >= '19' &&
                    <div>
                        <h3 className="text-secondary">Esta orden ya se encuentra completada, no puedes volverla a preparar</h3>
                        {props.customer_group_id !== "1" &&
                            <RelatedProducts data={relatedProducts} />
                        }
                    </div>


                }
                {
                    props.order_status_id < '18' &&
                    <h3 className="text-secondary">Esta orden aun no está pagada, no puedes prepararla</h3>
                }
            </Paper >
            {props.order_status_id === '18' &&
                < Paper className="p-3 mb-3">
                    <h5>Completar lista de empaque</h5>
                    {packingListCompleted &&
                        <>
                            <p className="text-secondary">La lista ya se encuentra completada</p>
                            <CustomizedSnackbars message="Lista completada con éxito" severity="success" />
                        </>
                    }
                    {!packingListCompleted &&
                        <>
                            <form noValidate autoComplete="off" style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <TextField className="mb-3" style={{ width: '100%' }} id="comments" label="Comentarios" type="text" onChange={(e) => { setComment(e.target.value) }} multiline rows={2} />
                            </form>
                            <form noValidate autoComplete="off" style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <TextField id="tracking" label="Número de guía" type="number" onChange={(e) => { setTrackingNumber(e.target.value) }} />
                                <Button variant="contained" color="primary" className="ml-3" onClick={completePackingList}>Completar</Button>
                            </form>
                        </>
                    }

                </Paper>
            }
            {alert.error &&
                <CustomizedSnackbars message={alert.message} severity="error" />
            }
            {alert.success &&
                <CustomizedSnackbars message={alert.message} severity="success" />
            }
        </div >
    )
}

export default PackingList