import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormattedNumber from '../assets/formattedNumber';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        width: '100%',
    },
});


const NoEncontradasTable = (props) => {
    const classes = useStyles();
    const total = props.data.reduce((accumulator, element) => {
        return accumulator + parseFloat(element.total);
    }, 0);



    return (
        <TableContainer id={'tabla_desglose'} component={Paper} className="mb-3" >
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Orden</StyledTableCell>
                        <StyledTableCell>Cantidad</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row) => (
                        <StyledTableRow key={row.order_id}>
                            <StyledTableCell >{row.order_id}</StyledTableCell>
                            <StyledTableCell ><FormattedNumber value={row.total} /></StyledTableCell>
                        </StyledTableRow>
                    ))}
                    <StyledTableRow key={total}>
                        <StyledTableCell><b>Total</b></StyledTableCell>
                        <StyledTableCell><b><FormattedNumber value={total} /></b></StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer >
    );
}
export default NoEncontradasTable
