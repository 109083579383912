import React from "react";
import { CircularProgress } from '@material-ui/core';

const loadingScreenStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: 'rgb(255 255 255 / 53%)',
    left: '0',
    right: '0',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '3rem 0'
}
const LoadingBlock = () => {
    return (
        <div style={loadingScreenStyle}>
            <CircularProgress />
        </div>
    )
}
export default LoadingBlock