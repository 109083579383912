import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import MaterialTable from 'material-table';
import Producto from './producto';
import { useHistory } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';

const Productos = (props) => {
    const [data, setdata] = useState([]);
    const [active] = useState({ productos: true, producto: false });
    const [productId] = useState(0);
    const [skeleton, useSkeleton] = useState(true);
    const history = useHistory();

    useEffect(() => {
        const endpoint = `${props.host}/productos`;
        const json = { "operation": "lista", "api_key": props.apiKey }
        Axios.post(endpoint, json)
            .then(res => {
                setdata(res.data);
                HideSkeleton();
            })
    }, [props.host, props.apiKey])


    const HideSkeleton = () => {
        useSkeleton(false)
    }

    function handleClick(product_id) {
        history.push(`/productos/catalogo/producto/${product_id}`)
    }

    return (

        <div>
            {skeleton &&
                <>
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />

                </>
            }
            {active.productos && !skeleton &&
                <div className="mb-3">
                    <MaterialTable
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}',
                                labelRowsSelect: 'Filas'
                            },
                            toolbar: {
                                nRowsSelected: '{0} filas(s) seleccionadas',
                                searchPlaceholder: 'Buscar'
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            body: {
                                emptyDataSourceMessage: 'No hay resultados que mostrar',
                                filterRow: {
                                    filterTooltip: 'Filtrar'
                                }
                            }
                        }}
                        // other props
                        title="Catálogo"
                        columns={[
                            { title: 'Imagen', field: 'image', render: rowData => <img src={rowData.image} style={{ width: '50px', height: '50px', objectFit: 'contain' }} alt="" /> },
                            { title: "Cantidad", field: "serialized_quantity" },
                            { title: "Modelo", field: "model" },
                            { title: "Nombre", field: "name" },
                            { title: "Estado en la tienda", field: "status" },

                        ]}
                        actions={[
                            {
                                icon: 'visibility',
                                tooltip: 'Ver producto',
                                onClick: (event, rowData) => handleClick(rowData.product_id)
                            }
                        ]}
                        options={{
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            pageSize: 10,
                            actionsColumnIndex: -1
                        }}
                        data={data}
                    />
                </div>
            }
            {active.producto && !skeleton &&
                <div>
                    <Producto apiKey={props.apiKey} productId={productId} />
                </div>
            }

        </div>
    )
}

export default Productos
