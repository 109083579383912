import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const MercadolibreStoreOrder = (props) => {
    const storeOrder = useSelector(state => state.mercadolibreStoreOrder.store_order.order);
    const storeOrderJson = useSelector(state => state.mercadolibreStoreOrder.store_order.store_order_json);
    const mercadolibreStats = useSelector(state => state.mercadolibreStoreOrder.mercadolibre_stats);
    const classes = useStyles();
    const [orderButton, setOrderButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const updateButton = () => {
        if (storeOrder.order_data.all_products_in_store) {
            setOrderButton(true);
        }
    }
    const cookies = new Cookies();
    const apiKey = cookies.get('token');
    const history = useHistory();
    const createOrder = () => {
        const endpoint = "https://erp.biowater.mx/endpoint/mercadolibre_store_order";
        const json = { "operation": "create", "api_key": apiKey, "data": storeOrderJson }
        Axios.post(endpoint, json)
            .then(res => {
                if (res.data.ok) {
                    history.push('/ordenes');
                }
                else {
                    setErrorMessage(res.data.error);
                }
            });
    }
    useEffect(() => updateButton()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

    return (
        <div>
            <h5>Vista previa de la orden</h5>
            <TableContainer component={Paper} className="mb-3">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Producto</StyledTableCell>
                            <StyledTableCell align="center">Modelo</StyledTableCell>
                            <StyledTableCell align="center">Cantidad</StyledTableCell>
                            <StyledTableCell align="right">Precio unitario</StyledTableCell>
                            <StyledTableCell align="right">Total</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {storeOrder.products.map((row) => (
                            <StyledTableRow key={row.title}>
                                <StyledTableCell component="th" scope="row">
                                    <>
                                        {row.title}
                                        {!row.product_exists_in_store &&
                                            <p style={{ color: 'red' }}>Este producto no se encuentra en Biowater Público</p>
                                        }
                                    </>
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.sku}</StyledTableCell>
                                <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <NumberFormat value={row.average_price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <NumberFormat value={row.total_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}

                        <StyledTableRow key={storeOrder.total}>
                            <StyledTableCell align="right"></StyledTableCell>
                            <StyledTableCell align="right"></StyledTableCell>
                            <StyledTableCell align="right"></StyledTableCell>
                            <StyledTableCell align="right">Total</StyledTableCell>
                            <StyledTableCell align="right">{storeOrder.total}</StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer>
            <h5>Datos provenientes de Mercadolibre</h5>
            <TableContainer component={Paper} className="mb-3">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Total</StyledTableCell>
                            <StyledTableCell align="center">Órdenes</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow key={mercadolibreStats}>
                            <StyledTableCell align="left">
                                {mercadolibreStats.total_amount}
                                {mercadolibreStats.total_amount !== storeOrder.total ? <p style={{ color: 'red' }}>El total de Mercadolibre y la tienda no coinciden</p> : ''}
                            </StyledTableCell>
                            <StyledTableCell align="center">{mercadolibreStats.orders}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {errorMessage &&
                <Alert style={{ marginBottom: '1rem' }} severity='error'>{errorMessage}</Alert>
            }

            {orderButton &&
                <Button onClick={() => createOrder()} style={{ marginBottom: '1rem', float: 'right' }} color="primary" variant="contained">Crear orden</Button>
            }
            {!orderButton &&
                <Button disabled style={{ marginBottom: '1rem', float: 'right' }} color="primary" variant="contained">Crear orden</Button>
            }


        </div>
    )
}
export default MercadolibreStoreOrder;