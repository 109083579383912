import React, { useState, useEffect, useCallback } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Axios from 'axios';
import { Button, Grid, InputLabel, Select, MenuItem, FormControl, Chip, IconButton, Dialog, DialogTitle, DialogContentText, Input, TextField } from '@material-ui/core';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormattedNumber from '../assets/formattedNumber';
import { Alert } from '@material-ui/lab';
import LoadingScreen from '../assets/loadingScreen';
import CustomAlert from '../assets/customAlert';
import { Edit, Save } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    }
});

const avatarStyle = {
    background: '#245c80'
}

const Facturar = ({ match, host, apiKey, storeUrl }) => {
    const [data, setData] = useState(false);
    const classes = useStyles();
    const [paymentMethod, setPaymentMethod] = useState("PUE");
    const [paymentForm, setPaymentForm] = useState("00");
    const [invoiceUse, setInvoiceUse] = useState("00");
    const [date, setDate] = useState(new Date());
    const [totalsWithoutSubtotal, setTotalsWithoutSubtotal] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [customAlert, setCustomAlert] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [taxDetailsEditable, setTaxDetailsEditable] = useState(false);
    const [relatedDocuments, setRelatedDocuments] = useState(null);
    const [relationShip, setRelationShip] = useState('0');

    const handlePaymentMethod = (event) => {
        let dataCopy = data;
        dataCopy.data_for_invoice.payment_method = event.target.value;
        setPaymentMethod(event.target.value);
        setData(dataCopy);
    };

    const handlePaymentForm = (event) => {
        let dataCopy = data;
        dataCopy.data_for_invoice.payment_form = event.target.value;
        setPaymentForm(event.target.value);
        setData(dataCopy);
    };

    const handleInvoiceUse = (event) => {
        let dataCopy = data;
        dataCopy.data_for_invoice.use = event.target.value;
        setInvoiceUse(event.target.value);
        setData(dataCopy);
    };

    const createCustomer = () => {
        setLoadingScreen(true);
        const endpoint = `${host}/clientes_facturacion`;
        const json = { "operation": "create", "api_key": apiKey, 'order_details': data.order_details, 'organization': data.organization[0], "customer_details": data.customer_details, 'store': storeUrl };
        Axios.post(endpoint, json)
            .then(res => {
                res.data.ok && fetchData();
                res.data.error && setCustomAlert({
                    'title': 'Error al ingresar el cliente',
                    'severity': 'error',
                    'message1': res.data.error.message
                });
            })
            .finally(res => setLoadingScreen(false))
    };

    const createInvoice = () => {
        let related_documents;
        if (relatedDocuments !== null) {
            related_documents = [relatedDocuments];
        }
        else {
            related_documents = null;
        }
        setLoadingScreen(true);
        const endpoint = `${host}/factura`;
        const json = { "operation": "create", "api_key": apiKey, "data_for_invoice": data.data_for_invoice, "date": date, 'organization': data.organization[0], "customer_details": data.customer_details, related_documents: related_documents };
        Axios.post(endpoint, json)
            .then(res => {
                console.log(res.data);
                res.data.ok && setCustomAlert({
                    'title': 'Se creo la factura correctamente',
                    'severity': 'success',
                    'message1': res.data.ok.uuid
                });
                res.data.error && setCustomAlert({
                    'title': 'Error al crear la factura',
                    'severity': 'error',
                    'message1': res.data.error
                });
            })
            .finally(res => setLoadingScreen(false))
    }

    const closeAlert = useCallback(() => {
        setCustomAlert(false);
    }, []);

    const handleTaxDetailsEditable = () => {
        setTaxDetailsEditable(!taxDetailsEditable)
    }

    const handleTaxDetails = (el) => {
        let dataCopy = data;
        if (el.target.name === 'tax_system') {
            dataCopy.order_details.tax_details.tax_system.code = el.target.value;
            dataCopy.order_details.tax_details.tax_system.name = el.target.options[el.target.options.selectedIndex].text;
        }
        else if (el.target.name === 'zip') {
            dataCopy.order_details.tax_details.address.zip = el.target.value;
        }
        else {
            dataCopy.order_details.tax_details[el.target.name] = el.target.value;
        }
        setData(dataCopy);

    }

    const handleRelatedDocuments = (e) => {
        let rd;
        if (relatedDocuments === null) { rd = { relationship: "04", documents: false }; }
        else {
            rd = { ...relatedDocuments };
        }

        if (e.target.name === 'uuid') {
            rd = { ...rd, documents: [e.target.value] };
        }
        if (e.target.name === 'relation_type_code') {
            rd = { ...rd, relationship: e.target.value };
            setRelationShip(e.target.value);
        }
        setRelatedDocuments(rd);
    }

    const fetchData = useCallback(() => {
        const endpoint = `${host}/factura`;
        const json = { "operation": "retrieve_details", "api_key": apiKey, "order_id": match.params.order_id, "store_url": storeUrl };
        Axios.post(endpoint, json)
            .then(res => {
                setData(res.data);
            })
    }, [apiKey, host, match.params.order_id, storeUrl])

    useEffect(() => {
        console.log(storeUrl);
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        data && setTotalsWithoutSubtotal(
            data.order_totals.filter((key) => { return key.name !== 'Sub-Total' })
        );
        data && data.order_details.tax_details.legal_name === 'VENTA AL PUBLICO EN GENERAL' && setInvoiceUse('S01')
    }, [data]);




    return (
        <>
            {loadingScreen &&
                <LoadingScreen />
            }

            {
                customAlert &&
                <CustomAlert
                    title={customAlert.title}
                    severity={customAlert.severity}
                    message1={customAlert.message1}
                    closeAlert={closeAlert}
                />
            }

            {data &&

                <>
                    {data.order_details.facturable !== 'no' &&
                        <>
                            <Alert style={{ marginBottom: '1rem' }} severity='info' elevation={1}>
                                {data.organization.map((row) => (
                                    <p key={row.nombre}>La factura o registro de cliente se realizará con: <b>{row.nombre}</b></p>
                                ))
                                }
                            </Alert>

                            {!data.customer_details.facturapi_id &&
                                <>
                                    < Alert style={{ marginBottom: '1rem' }} elevation={1} severity="error">Cliente no registrado</Alert>
                                    <Button disabled={taxDetailsEditable} onClick={createCustomer} style={{ marginBottom: '1rem', float: 'right' }} variant='contained' color="primary">Registrar Cliente</Button>
                                </>
                            }
                        </>
                    }

                    {data.invoice_details.exists &&
                        <>
                            < Alert style={{ marginBottom: '1rem' }} elevation={1} severity="warning">
                                Esta orden ya cuenta con una o más facturas
                                <List dense={true}>
                                    {data.invoice_details.invoices.map((row) => (
                                        <>
                                            <p>{row.fecha}</p>
                                            <ListItem key={row.id_fact}>
                                                <ListItemText primary={`${row.id_fact}`} secondary={row.status}></ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={`${row.uuid}`} ></ListItemText>
                                            </ListItem>
                                        </>
                                    ))
                                    }
                                </List>
                            </Alert>
                        </>
                    }
                    {!data.invoice_details.matches &&
                        <>
                            < Alert style={{ marginBottom: '1rem' }} elevation={1} severity="error">
                                Los datos de facturación no concuerdan con los registrados. Los datos con que se facturará son los siguientes:
                                <List>
                                    {data.invoice_details.customer_data.map((row) => (
                                        <>
                                            <ListItem>RFC: {row.tax_id}</ListItem>
                                            <ListItem>Razón Social: {row.legal_name}</ListItem>
                                        </>
                                    ))}
                                </List>
                            </Alert>
                        </>
                    }

                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={6}>
                            <Paper style={{ marginBottom: '1rem', height: '310px' }}>
                                <h4 className="p-3" style={{ color: '#545b62' }}>Orden #{match.params.order_id}</h4>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">person</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Nombre" secondary={`${data.order_details.firstname} ${data.order_details.lastname}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">alternate_email</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Email" secondary={data.order_details.email} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">location_on</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Dirección" secondary={`${data.order_details.payment_address_1}, ${data.order_details.payment_postcode}`} />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Paper style={{ marginBottom: '1rem', height: '310px' }}>
                                <IconButton onClick={handleTaxDetailsEditable} style={{ right: '0', position: 'absolute', marginRight: '1rem', zIndex: '1' }}>
                                    {!taxDetailsEditable && <Edit />}
                                    {taxDetailsEditable && <Save />}
                                </IconButton>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">corporate_fare</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        {!taxDetailsEditable && <ListItemText primary="Razón Social" secondary={data.order_details.tax_details.legal_name} />}
                                        {taxDetailsEditable && <ListItemText primary="Razón Social" secondary={<input name="legal_name" onChange={handleTaxDetails} style={{ width: '325px' }} defaultValue={data.order_details.tax_details.legal_name} />} />}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">fingerprint</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        {!taxDetailsEditable && <ListItemText primary="R.F.C" secondary={data.order_details.tax_details.tax_id} />}
                                        {taxDetailsEditable && <ListItemText primary="R.F.C" secondary={<input style={{ width: '325px' }} name="tax_id" onChange={handleTaxDetails} defaultValue={data.order_details.tax_details.tax_id} />} />}

                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">people</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        {!taxDetailsEditable && <ListItemText primary="Régimen Fiscal" secondary={data.order_details.tax_details.tax_system.name} />}
                                        {taxDetailsEditable &&
                                            <select onChange={handleTaxDetails} name="tax_system" style={{ width: '325px' }} defaultValue={data.order_details.tax_details.tax_system.code}>
                                                <option value="0">Sin régimen fiscal asociado</option>
                                                <option value="601"> General de Ley Personas Morales</option>
                                                <option value="603"> Personas Morales con Fines no Lucrativos</option>
                                                <option value="605"> Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                                                <option value="606"> Arrendamiento</option>
                                                <option value="607"> Régimen de Enajenación o Adquisición de Bienes</option>
                                                <option value="608"> Demás ingresos</option>
                                                <option value="609"> Consolidación</option>
                                                <option value="610"> Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                                                <option value="611"> Ingresos por Dividendos (socios y accionistas)</option>
                                                <option value="612"> Personas Físicas con Actividades Empresariales y Profesionales</option>
                                                <option value="614"> Ingresos por intereses</option>
                                                <option value="615"> Régimen de los ingresos por obtención de premios</option>
                                                <option value="616"> Sin obligaciones fiscales</option>
                                                <option value="620"> Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                                                <option value="621"> Incorporación Fiscal</option>
                                                <option value="622"> Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                                                <option value="623"> Opcional para Grupos de Sociedades</option>
                                                <option value="624"> Coordinados</option>
                                                <option value="625"> Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                                                <option value="626"> Régimen Simplificado de Confianza</option>
                                                <option value="628"> Hidrocarburos</option>
                                                <option value="629"> De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
                                                <option value="630"> Enajenación de acciones en bolsa de valores</option>
                                            </select>
                                        }

                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">local_post_office</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        {!taxDetailsEditable && <ListItemText primary="Código postal fiscal" secondary={data.order_details.tax_details.address.zip} />}
                                        {taxDetailsEditable && <ListItemText primary="Código postal fiscal" secondary={<input name="zip" onChange={handleTaxDetails} style={{ width: '325px' }} defaultValue={data.order_details.tax_details.address.zip} />} />}

                                    </ListItem>

                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                    <TableContainer id={'tabla_desglose'} component={Paper} className="mb-3">
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Producto</StyledTableCell>
                                    <StyledTableCell align="center">Modelo</StyledTableCell>
                                    <StyledTableCell align="center">Clave SAT</StyledTableCell>
                                    <StyledTableCell align="right">Unidad SAT</StyledTableCell>
                                    <StyledTableCell align="right">Unidad</StyledTableCell>
                                    <StyledTableCell align="right">Cantidad</StyledTableCell>
                                    <StyledTableCell align="right">Precio</StyledTableCell>
                                    <StyledTableCell align="right">Total</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {data.order_products.products.map((row) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row">{row.name}</StyledTableCell>
                                        <StyledTableCell align="center">{row.model}</StyledTableCell>
                                        <StyledTableCell align="center">{row.prdSAT}</StyledTableCell>
                                        <StyledTableCell align="center">{row.uniSAT}</StyledTableCell>
                                        <StyledTableCell align="center">{row.unidad}</StyledTableCell>
                                        <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                                        <StyledTableCell align="right"><FormattedNumber value={row.price} /></StyledTableCell>
                                        <StyledTableCell align="right"><FormattedNumber value={row.total} /></StyledTableCell>
                                    </StyledTableRow>
                                ))}

                                <StyledTableRow key="subtotal">
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right">Subtotal</StyledTableCell>
                                    <StyledTableCell align="right"><FormattedNumber value={data.order_totals[0].total} /></StyledTableCell>
                                </StyledTableRow>

                                {data.order_discounts.map((row) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="right"></StyledTableCell>
                                        <StyledTableCell align="right">{row.name}</StyledTableCell>
                                        <StyledTableCell align="right"><FormattedNumber value={row.total} /></StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                {totalsWithoutSubtotal &&
                                    totalsWithoutSubtotal.map((row) => (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                            <StyledTableCell align="right">{row.name}</StyledTableCell>
                                            <StyledTableCell align="right"><FormattedNumber value={row.total} /></StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
                                <h5>Fecha</h5>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ marginBottom: '1rem' }}
                                        disableToolbar
                                        autoOk="true"
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Fecha de la factura"
                                        value={date}
                                        onChange={setDate}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />

                                </MuiPickersUtilsProvider>
                            </Paper>
                            <Dialog open={dialogOpen}>
                                <DialogTitle>Agregar factura relacionada</DialogTitle>
                                <FormControl className={classes.formControl} style={{ margin: '1rem' }}>
                                    <TextField label="uuid" name="uuid" onChange={handleRelatedDocuments} />
                                </FormControl>
                                <FormControl className={classes.formControl} style={{ margin: '1rem' }}>
                                    <InputLabel id="relacion-label">Tipo de relación</InputLabel>
                                    <Select value={relationShip} onChange={handleRelatedDocuments} name="relation_type_code" labelId="relacion-label" id="relacion" >
                                        <MenuItem value="01">Nota de crédito de los documentos relacionados</MenuItem>
                                        <MenuItem value="02">Nota de débito de los documentos relacionados</MenuItem>
                                        <MenuItem value="03">Devolución de mercancía sobre facturas o traslados previos</MenuItem>
                                        <MenuItem value="04">Sustitución de los CFDI previos</MenuItem>
                                        <MenuItem value="05">Traslados de mercancias facturados previamente</MenuItem>
                                        <MenuItem value="06">Factura generada por los traslados previos</MenuItem>
                                        <MenuItem value="07">CFDI por aplicación de anticipo</MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{ margin: '1rem', display: 'flex' }}>
                                    <Button onClick={() => setDialogOpen(false)} style={{ maxWidth: '150px', marginRight: '1rem' }} color="default" variant='contained'>Cancelar</Button>
                                    <Button onClick={() => setDialogOpen(false)} style={{ maxWidth: '150px' }} color="primary" variant='contained'>Ok</Button>
                                </div>

                            </Dialog>
                            <Paper style={{ padding: '1rem' }}>
                                <h5 style={{ display: 'inline-block' }}>Documentos relacionados</h5>
                                <IconButton onClick={() => setDialogOpen(true)}>
                                    <AddCircleIcon />
                                </IconButton>
                                <br />
                                {relatedDocuments != null && relatedDocuments.documents.length > 0 &&
                                    relatedDocuments.documents.map((i) => (
                                        <>{i}</>
                                    ))
                                }
                            </Paper>

                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
                                <h5>Método de pago y uso de factura</h5>
                                <Chip style={{ marginBottom: '1rem' }} label={data.order_details.payment_method} color="secondary" /><br />
                                <FormControl className={classes.formControl} style={{ width: '100%', marginBottom: '1rem' }}>
                                    <InputLabel id="demo-simple-select-label">Forma de pago</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={paymentForm}
                                        onChange={handlePaymentForm}
                                    >
                                        <MenuItem value={'00'}>-- Elegir uno --</MenuItem>
                                        <MenuItem value={'01'}>Efectivo</MenuItem>
                                        <MenuItem value={'02'}>Cheque nominativo</MenuItem>
                                        <MenuItem value={'03'}>Transferencia electrónica de fondos</MenuItem>
                                        <MenuItem value={'04'}>Tarjeta de crédito</MenuItem>
                                        <MenuItem value={'05'}>Monedero electrónico</MenuItem>
                                        <MenuItem value={'06'}>Dinero electrónico</MenuItem>
                                        <MenuItem value={'08'}>Vales de despensa</MenuItem>
                                        <MenuItem value={'12'}>Dación en pago</MenuItem>
                                        <MenuItem value={'13'}>Pago por subrogación</MenuItem>
                                        <MenuItem value={'14'}>Pago por consignación</MenuItem>
                                        <MenuItem value={'15'}>Condonación</MenuItem>
                                        <MenuItem value={'17'}>Compensación</MenuItem>
                                        <MenuItem value={'23'}>Novación</MenuItem>
                                        <MenuItem value={'24'}>Confusión</MenuItem>
                                        <MenuItem value={'25'}>Remisión de deuda</MenuItem>
                                        <MenuItem value={'26'}>Prescripción o caducidad</MenuItem>
                                        <MenuItem value={'27'}>A satisfacción del acreedor</MenuItem>
                                        <MenuItem value={'28'}>Tarjeta de débito</MenuItem>
                                        <MenuItem value={'29'}>Tarjeta de servicios</MenuItem>
                                        <MenuItem value={'30'}>Aplicación de anticipos</MenuItem>
                                        <MenuItem value={'31'}>Intermediario pagos</MenuItem>
                                        <MenuItem value={'99'}>Por definir</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <FormControl className={classes.formControl} style={{ width: '100%', marginBottom: '1rem' }}>
                                    <InputLabel id="demo-simple-select-label">Método de pago</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={paymentMethod}
                                        onChange={handlePaymentMethod}
                                    >
                                        <MenuItem value={'PUE'}>Pago en una sola exhibición</MenuItem>
                                        <MenuItem value={'PPD'}>Pago en parcialidades o diferido</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />

                                <FormControl className={classes.formControl} style={{ width: '100%', marginBottom: '1rem' }}>
                                    <InputLabel id="demo-simple-select-label">Uso de factura</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={invoiceUse}
                                        onChange={handleInvoiceUse}
                                    >
                                        <MenuItem value={'00'}>-- Elegir uno --</MenuItem>
                                        <MenuItem value={'G01'}> Adquisición de mercancias</MenuItem>
                                        <MenuItem value={'G03'}>Gastos en general</MenuItem>
                                        <MenuItem value={'S01'}> Sin Obligaciones Fiscales</MenuItem>
                                        <MenuItem value={'G02'}> Devoluciones, descuentos o bonificaciones</MenuItem>
                                        <MenuItem value={'I01'}> Construcciones</MenuItem>
                                        <MenuItem value={'I02'}> Mobilario y equipo de oficina por inversiones</MenuItem>
                                        <MenuItem value={'I03'}> Equipo de transporte</MenuItem>
                                        <MenuItem value={'I04'}> Equipo de computo y accesorios</MenuItem>
                                        <MenuItem value={'I05'}> Dados, troqueles, moldes, matrices y herramental</MenuItem>
                                        <MenuItem value={'I06'}> Comunicaciones telefónicas</MenuItem>
                                        <MenuItem value={'I07'}> Comunicaciones satelitales</MenuItem>
                                        <MenuItem value={'I08'}> Otra maquinaria y equipo</MenuItem>
                                        <MenuItem value={'D01'}> Honorarios médicos, dentales y gastos hospitalarios</MenuItem>
                                        <MenuItem value={'D02'}> Gastos médicos por incapacidad o discapacidad</MenuItem>
                                        <MenuItem value={'D03'}> Gastos funerales</MenuItem>
                                        <MenuItem value={'D04'}> Donativos</MenuItem>
                                        <MenuItem value={'D05'}> Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)</MenuItem>
                                        <MenuItem value={'D06'}> Aportaciones voluntarias al SAR</MenuItem>
                                        <MenuItem value={'D07'}> Primas por seguros de gastos médicos</MenuItem>
                                        <MenuItem value={'D08'}> Gastos de transportación escolar obligatoria</MenuItem>
                                        <MenuItem value={'D09'}> Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</MenuItem>
                                        <MenuItem value={'D10'}> Pagos por servicios educativos (colegiaturas)</MenuItem>
                                        <MenuItem value={'P01'}>Por definir</MenuItem>
                                    </Select>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                    {data.order_details.facturable === 'no' &&
                        <Alert elevation={1} style={{ marginBottom: '1rem', float: 'right' }} severity='info'>La orden se pagó en cuenta no fiscal</Alert>

                    }
                    {!data.customer_details.facturapi_id &&
                        <Alert elevation={1} style={{ marginBottom: '1rem', float: 'right' }} severity='info'>El cliente no está registrado</Alert>
                    }
                    {
                        data.customer_details.facturapi_id &&
                        <>
                            {
                                data.order_details.facturable === undefined &&
                                <Button onClick={createInvoice} style={{ marginBottom: '1rem', float: 'right' }} variant='contained' color='primary'>Facturar</Button>
                            }
                            {
                                data.order_details.facturable === "" &&
                                <Button onClick={createInvoice} style={{ marginBottom: '1rem', float: 'right' }} variant='contained' color='primary'>Facturar</Button>
                            }
                        </>

                    }

                </>

            }
        </>





    )
}
export default Facturar