import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import MaterialTable from 'material-table';
import TextField from '@material-ui/core/TextField';
import { Button, Chip, FormControlLabel, Paper } from "@material-ui/core";
import FormattedNumber from "../assets/formattedNumber";
import { Switch } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Cookies from "universal-cookie";

const ListaPrecios = (props) => {
    const [data, setData] = useState(false);
    const [exchangeRate, setExchangeRate] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [pricesWithTax, setPricesWithTax] = useState(false);
    const endpoint = `${props.host}/precios`;
    const [skeleton, setSkeleton] = useState(true);
    const cookies = new Cookies();
    const userLevel = cookies.get('level');

    const handleExchangeRate = (el) => {
        setExchangeRate(el.target.value);
    }
    const handlePercentage = (el) => {
        setPercentage(el.target.value);
    }
    const handlePricesWithTax = (el) => {
        setPricesWithTax(el.target.checked);
    }

    const handleRestoreList = (listId) => {
        let json = { 'operation': 'restore_list', 'api_key': props.apiKey, 'list_id': listId };
        setData(false);
        Axios.post(endpoint, json)
            .then(fetchData())
    }

    const handleChangeGlobalPrices = () => {
        let json = { 'operation': 'change_global_prices', 'api_key': props.apiKey, 'exchange_rate': exchangeRate, 'adjustment_percentage': percentage }
        setData(false);
        Axios.post(endpoint, json)
            .then(fetchData)
    }

    const fetchData = useCallback(() => {
        let operation;
        pricesWithTax ? operation = 'all_with_tax' : operation = 'all';
        let json = { 'operation': operation, 'api_key': props.apiKey };
        Axios.post(endpoint, json)
            .then(res => {
                setData(res.data);
                setExchangeRate(res.data.exchange_rate);
            })
    }, [endpoint, pricesWithTax, props.apiKey])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        !data && setSkeleton(true);
        data && setSkeleton(false);
    }, [data])


    return (
        <>

            {skeleton &&
                <>
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />

                </>
            }
            {!skeleton &&
                <>
                    <div className="row">

                        <div className="col-sm-4">
                            {userLevel === 'admin' &&
                                <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
                                    <h5>Ajustar precios</h5>
                                    <div className="row mb-3">
                                        <div className="col-sm-12"><TextField id="standard-basic" type="number" inputProps={{ step: '0.1' }} label="Tipo de cambio USD" value={exchangeRate} onChange={handleExchangeRate} /></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-12"><TextField id="standard-basic" type="number" label="Porcentaje de Ajuste" value={percentage} onChange={handlePercentage} /></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-12"><Button onClick={handleChangeGlobalPrices} variant="contained" color="primary">Cambiar precios</Button></div>
                                    </div>
                                </Paper>
                            }
                            <div className="row">
                                <div className=" col-sm-12">
                                    <FormControlLabel
                                        control={<Switch
                                            checked={pricesWithTax}
                                            onChange={handlePricesWithTax}
                                            name="checkedB"
                                            color="primary"
                                        />}
                                        label="Ver precios con IVA"
                                    />

                                </div>
                            </div>
                        </div>

                        <div className="col-sm-8">
                            {userLevel === 'admin' &&
                                <MaterialTable
                                    style={{ marginBottom: '1rem' }}
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} de {count}',
                                            labelRowsSelect: 'Filas'
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} filas(s) seleccionadas',
                                            searchPlaceholder: 'Buscar'
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'No hay resultados que mostrar',
                                            filterRow: {
                                                filterTooltip: 'Filtrar'
                                            }
                                        }
                                    }}
                                    columns={[
                                        { title: 'ID', field: 'list_id', defaultSort: 'desc' },
                                        { title: 'Fecha vigencia', field: 'effective_date' },
                                        { title: 'Tipo de cambio', field: 'tipo_de_cambio' },
                                        { title: 'Porcentaje de ajuste', field: 'porcentaje' },
                                        {
                                            title: '', field: 'active', render: rowData => {
                                                let style;
                                                let label;
                                                rowData.active ? style = { background: '#28a745', color: 'white' } : style = {};
                                                rowData.active ? label = "Activa" : label = "Inactiva"
                                                return <Chip label={label} style={style} />
                                            }
                                        }

                                    ]}
                                    actions={[
                                        {
                                            icon: 'restore',
                                            tooltip: 'Restaurar Lista',
                                            onClick: (event, rowData) => handleRestoreList(rowData.list_id)
                                        }
                                    ]}
                                    data={data.history}
                                    options={{
                                        actionsColumnIndex: -1,
                                        padding: 'dense',
                                        pageSize: 5,
                                        headerStyle: {
                                            backgroundColor: '#245c80',
                                            color: '#FFF'
                                        },
                                        filtering: false,
                                        search: false
                                    }}
                                    title="Historial de listas"
                                />
                            }
                        </div>

                    </div>

                    <MaterialTable
                        style={{ marginBottom: '1rem' }}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}',
                                labelRowsSelect: 'Filas'
                            },
                            toolbar: {
                                nRowsSelected: '{0} filas(s) seleccionadas',
                                searchPlaceholder: 'Buscar',
                                exportTitle: 'Descargar Excel'
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            body: {
                                emptyDataSourceMessage: 'No hay resultados que mostrar',
                                filterRow: {
                                    filterTooltip: 'Filtrar'
                                }
                            }
                        }}
                        columns={[
                            { title: 'Modelo', field: 'model' },
                            { title: 'Descripción', field: 'name' },
                            { title: 'Precio público', field: 'price', render: rowData => { return <FormattedNumber value={rowData.price} /> } },
                            { title: 'Nivel 1', field: 'price_level_1', render: rowData => { return <FormattedNumber value={rowData.price_level_1} /> } },
                            { title: 'Nivel 2', field: 'price_level_2', render: rowData => { return <FormattedNumber value={rowData.price_level_2} /> } },
                            { title: 'Nivel 3', field: 'price_level_3', render: rowData => { return <FormattedNumber value={rowData.price_level_3} /> } },
                            { title: 'Precio USD', field: 'price_usd', render: rowData => { return <FormattedNumber value={rowData.price_usd} /> } },
                            {
                                title: 'Estado', field: 'status', render: rowData => {
                                    let status;
                                    rowData.status === "1" ? status = 'Activo' : status = 'Inactivo';
                                    return status;
                                }
                            },

                        ]}
                        data={data.list}
                        options={{
                            pageSize: 10,
                            exportButton: true,
                            exportFileName: 'Lista de precios',
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            actionsColumnIndex: -1,
                            filtering: true
                        }}
                        title="Lista de precios"
                    />
                </>
            }
        </>
    );
}

export default ListaPrecios;