import React, { useState, useEffect, useContext } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import OrdenesTable from './ordenesTable';
import Button from '@material-ui/core/Button';
import Axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import MercadolibreTable from '../mercadolibre/mercadolibreTable';
import { CircularProgress, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { OptionsContext } from '../../App';


const Ordenes = (props) => {
    let history = useHistory();
    const [options, setOptions] = useContext(OptionsContext);
    const date = new Date();
    const [data, setData] = useState(false);
    const [selectedFrom, setSelectedFrom] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [selectedTo, setSelectedTo] = useState(new Date());
    const [jsonRequest, setJsonRequest] = useState({ "operation": "this_month", "api_key": props.apiKey });
    const [skeleton, setSkeleton] = useState(true);
    const [ordenesTableTitle, setOrdenesTableTitle] = useState('Este mes y pendientes');
    const [productsNotInStore, setProductsNotInStore] = useState(false);
    const [invoicePending, setInvoicePending] = useState(false);


    const handleDateFrom = (date) => {
        setSelectedFrom(date);
    }

    const handleDateTo = (date) => {
        setSelectedTo(date);
    }

    const handleJsonRequest = () => {
        setJsonRequest({
            "operation": "filtered",
            "api_key": props.apiKey,
            "date_from": selectedFrom.getFullYear() + "-" + (selectedFrom.getMonth() + 1) + '-' + selectedFrom.getDate(),
            "date_to": selectedTo.getFullYear() + "-" + (selectedTo.getMonth() + 1) + '-' + selectedTo.getDate()
        });
        setOrdenesTableTitle('Filtrado por fecha');
    }

    const handleInvoicePending = (event) => {
        setInvoicePending(event.target.checked);
        setOptions({ 'showInvoicePendingOrders': event.target.checked });

    }

    useEffect(() => {
        setInvoicePending(options.showInvoicePendingOrders);
    }, [options])

    //Skeleton loading
    useEffect(() => {
        if (!data) {
            setSkeleton(true);
        } else {
            setSkeleton(false);
        }
    }, [data]);

    //Fetch data
    useEffect(() => {
        let endpoint = `${props.host}/ordenes`;
        setSkeleton(true);
        Axios.post(endpoint, jsonRequest)
            .then(res => {
                //console.log(res.data);
                setData(res.data);
            })
    }, [jsonRequest, props.host]);

    //Update data on order changes
    useEffect(() => {
        if (data && data.mercadolibre.data.order_needs_update && data.mercadolibre.store_order.order_data.all_products_in_store) {
            let endpoint = `${props.host}/mercadolibre_store_order`;
            let json = { "operation": "update", "api_key": props.apiKey, "data": data.mercadolibre.store_order_json }
            Axios.post(endpoint, json)
                .then(res => {
                    if (res.data.ok) {
                        setJsonRequest({ "operation": "this_month", "api_key": props.apiKey });
                    }
                    else {
                        alert(res.data.error);
                    }
                });

        } else if (data && data.mercadolibre.data.order_needs_update && !data.mercadolibre.store_order.order_data.all_products_in_store) {
            setProductsNotInStore(data.mercadolibre.store_order.products.filter((val) => { return !val['product_exists_in_store'] }));
        }
    },
        [data, props.host, props.apiKey, history]);

    return (
        <div>
            {!skeleton && data.mercadolibre.data.order_needs_update &&
                <Paper style={{ padding: '1rem', textAlign: 'center' }}>
                    {!productsNotInStore &&
                        <>
                            <h5>Actualizando datos en la orden de Mercadolibre, espera por favor...</h5>
                            <CircularProgress />
                        </>
                    }
                    {productsNotInStore &&
                        <>
                            <h5>Los siguientes productos a actualizar no se encuentran en la tienda, es necesario ingresarlos para poder actualizar la orden:</h5>
                            {productsNotInStore.map((product) => (
                                <div key={product.sku}>{product.title} - {product.sku}</div>
                            ))}
                        </>
                    }
                </Paper>
            }

            <div className="row mb-3" style={{ alignItems: 'center' }}>
                <div className="col-md-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk="true"
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Desde"
                            value={selectedFrom}
                            onChange={handleDateFrom}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />

                    </MuiPickersUtilsProvider>
                </div>
                <div className="col-md-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk="true"
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Hasta"
                            value={selectedTo}
                            onChange={handleDateTo}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />

                    </MuiPickersUtilsProvider>

                </div>
                <div className="col-md-2">
                    <Button variant="contained" color="primary" onClick={handleJsonRequest} >Filtrar</Button>

                </div>
                <div className='col-md-3 offset-md-3 align-items-center'>
                    <FormControlLabel
                        style={{ margin: '0', float: 'right' }}
                        control={
                            <Switch
                                checked={invoicePending}
                                onChange={handleInvoicePending}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Pendientes a facturar"
                    />
                </div>
            </div>

            {skeleton &&
                <>
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />

                </>
            }
            {!skeleton &&
                <>
                    < OrdenesTable data={data.orders} title={ordenesTableTitle} setStoreForInvoice={props.setStoreForInvoice} defaultFilter={invoicePending} />
                    <MercadolibreTable data={data.mercadolibre.data} storeOrderJson={data.mercadolibre.store_order_json} storeOrder={data.mercadolibre.store_order} />
                </>

            }


        </div>
    )
}

export default Ordenes