import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import MaterialTable from 'material-table';
import Skeleton from '@material-ui/lab/Skeleton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '90%',
        },
    },
}));

const ClientesFacturacion = (props) => {
    const [data, setData] = useState(false);
    const [skeleton, setSkeleton] = useState(true);
    const endpoint = `${props.host}/clientes_facturacion`;
    const [showModal, setShowModal] = useState(false);
    const classes = useStyles();
    const [customerToEdit, setCustomerToEdit] = useState(false);
    const cookies = new Cookies();
    const userLevel = cookies.get('level');

    const closeModal = () => setShowModal(false);

    const fetchData = useCallback(() => {
        const json = { "operation": "lista", "api_key": props.apiKey };
        setSkeleton(true);
        Axios.post(endpoint, json)
            .then(res => {
                setData(res.data);
            })
    }, [endpoint, props.apiKey]);

    const fetchCustomerToEdit = (id, customer_id) => {
        const json = { "operation": "cliente", "api_key": props.apiKey, 'id': id, 'customer_id': customer_id };
        Axios.post(endpoint, json)
            .then(res => {
                setCustomerToEdit(res.data);
            })
    }
    const updateCustomerToEdit = (current, val) => {
        let customerToEditCopy = customerToEdit;
        customerToEditCopy.customer_data[0][val] = current.target.value;
        setCustomerToEdit(customerToEditCopy);
    }

    const updateCustomer = () => {
        const json = { "operation": "update", "api_key": props.apiKey, "data": customerToEdit };
        Axios.post(endpoint, json)
            .then(res => {
                if (res.data.ok) {
                    closeModal();
                    fetchData();
                } else if (res.data.error) {
                    alert(res.data.error);
                }
            })
    }

    const removeCustomer = (id, organization) => {
        if (window.confirm('¿Estás seguro que deseas eliminar este cliente? Esta acción no se puede deshacer')) {
            const json = { "operation": "remove", "api_key": props.apiKey, "id": id, "organization": organization };
            Axios.post(endpoint, json)
                .then(res => {
                    if (res.data.ok) {
                        fetchData();
                    } else if (res.data.error) {
                        alert(res.data.error);
                    }
                })
        }
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        customerToEdit && setShowModal(true);
    }, [customerToEdit]);



    useEffect(() => {
        !data && setSkeleton(true);
        data && setSkeleton(false);
    }, [data]);

    return (

        <div>
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className={classes.root} noValidate autoComplete="off">
                        {customerToEdit &&
                            customerToEdit.customer_data.map((row) => (
                                <>
                                    <div>
                                        <b key={row.name}>{row.name}</b>
                                    </div>
                                    <div>
                                        <p key={row.id}>id: {row.id}</p>
                                    </div>


                                    <TextField key={row.legal_name} id="standard-basic" label="Razón Social" defaultValue={row.legal_name} onChange={(val) => updateCustomerToEdit(val, 'legal_name')} />
                                    <TextField key={row.tax_id} id="standard-basic" label="RFC" defaultValue={row.tax_id} onChange={(val) => updateCustomerToEdit(val, 'tax_id')} />
                                    <TextField key={row.email} id="standard-basic" label="Email" defaultValue={row.email} onChange={(val) => updateCustomerToEdit(val, 'email')} />
                                </>
                            ))

                        }

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={updateCustomer}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                skeleton &&
                <>
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />
                    <br />
                    <Skeleton animation="wave" variant="rect" height={60} />

                </>
            }
            {
                !skeleton &&
                <div className="mb-3">

                    <MaterialTable
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}',
                                labelRowsSelect: 'Filas'
                            },
                            toolbar: {
                                nRowsSelected: '{0} filas(s) seleccionadas',
                                searchPlaceholder: 'Buscar'
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            body: {
                                emptyDataSourceMessage: 'No hay resultados que mostrar',
                                filterRow: {
                                    filterTooltip: 'Filtrar'
                                }
                            }
                        }}
                        // other props
                        title="Clientes"
                        columns={[
                            { title: "Nombre", field: 'name' },
                            { title: "Razón Social", field: "legal_name" },
                            { title: "RFC", field: "tax_id" },
                            { title: "Email", field: "email" }
                        ]}
                        actions={[
                            rowData => ({
                                icon: 'edit',
                                tooltip: 'Editar cliente',
                                onClick: () => fetchCustomerToEdit(rowData.id, rowData.customer_id),
                                disabled: userLevel !== 'admin'
                            }),
                            rowData => ({
                                icon: 'delete',
                                tooltip: 'Borrar cliente',
                                onClick: () => removeCustomer(rowData.id, rowData.organization),
                                disabled: userLevel !== 'admin'
                            })

                        ]}
                        options={{
                            headerStyle: {
                                backgroundColor: '#245c80',
                                color: '#FFF'
                            },
                            pageSize: 10,
                            actionsColumnIndex: -1
                        }}
                        data={data}

                    />
                </div>

            }

        </div >
    )
}

export default ClientesFacturacion
