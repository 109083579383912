import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const PackingBoxItem = (props) => {
    let url;
    let history = useHistory();
    const [state, setState] = useState({ boxState: props.boxState })
    const imgStyle = {
        display: 'block',
        margin: '0 auto',
        width: '150px',
        height: '150px',
        objectFit: 'contain'
    }
    const complete = () => {
        setState({ boxState: 'success' })
        props.handleCheckedBoxes()
    }

    let showCompleteButton = true

    let boxStyle = {}

    switch (state.boxState) {
        case 'danger':
            boxStyle = "border-danger"
            break
        case 'success':
            boxStyle = "border-success"
            break
        default:
            boxStyle = "border-warning"
            break
    }

    const badgeStyle = {
        position: 'absolute',
        top: '0',
        margin: '25%',
        height: '40px',
        width: '40px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#dc3545',
        justifyContent: 'center',
        fontSize: '1.3rem'
    }

    const optionStyle = {
        backgroundColor: '#245c80',
        padding: '0.5rem',
        borderRadius: '5px',
        color: 'white'
    }

    if (props.data.is_complete === false && props.data.category_id !== '64' && props.data.customer_group_id !== '1' && props.data.product_id !== '290' && props.data.product_id !== '296') { showCompleteButton = false }
    if (state.boxState === 'success') { showCompleteButton = false }
    if (props.data.customer_group_id === '1') { url = "http://biowater.mx/image/" }
    else { url = "http://distribuidores.biowater.mx/image/" }

    return (

        <div>

            <div className="row p-3">

                <div className="col-12 col-md-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <img className="mb-3" style={imgStyle} src={url + props.data.image} alt="imagen de producto" />
                    <span className="badge badge-primary badge-pill" style={badgeStyle}>{props.data.desired_quantity}</span>
                </div>
                <div className={boxStyle + " card p-3 col-12 col-md-9"} >
                    <p><b>{props.data.name} ({props.data.model})</b> </p>
                    {props.data.options.length > 0 &&
                        <p style={optionStyle}>
                            {Object.values(props.data.options).map((item, index) =>
                                <span key={index}>
                                    {item.name}: {item.value} <br />
                                </span>
                            )}
                        </p>
                        //<p style={optionStyle}>{props.data.option_name}: {props.data.option_description}</p>
                    }
                    {state.boxState === 'success' &&
                        <span className="completed-mark">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                            </svg>
                        </span>
                    }

                    <hr></hr>
                    <div>
                        <p>Números de serie asignados:</p>
                        {!props.serialRequired && props.data.customer_group_id !== '1' && <p className="text-secondary">No se requiere número de serie</p>}
                        {props.data.customer_group_id === '1' && <p className="text-secondary">No se requiere número de serie</p>}
                    </div>
                    <ul className="list-group mb-3">
                        {props.data.products.map((item, index) => (
                            <li key={index} className="list-group-item">{item.serial_number}</li>
                        ))}
                    </ul>
                    {showCompleteButton && <button onClick={() => complete()} className="btn btn-sm btn-warning boton-completado" style={{ width: '200px' }}>Marcar como revisado</button>}
                    {props.boxState === 'danger' &&
                        <div className="alert alert-danger">
                            <span>No hay suficientes unidades de este producto en Producto terminado, </span>
                            <button style={{ background: 'transparent', border: 'none' }} onClick={() => history.push(`/productos/catalogo/producto/${props.data.product_id}`)}>ingresa más números de serie a este producto</button>
                            <span>para poder cerrar esta orden.</span>
                        </div>
                    }

                </div>

            </div>
            <hr></hr>
        </div>

    )
}

export default PackingBoxItem