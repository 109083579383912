import React from 'react'
import MaterialTable from 'material-table'

const RelatedProducts = (props) => {
    const data = props.data[0]

    return (

        <div>
            <div className="mt-3">
                <MaterialTable
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'Filas'
                        },
                        toolbar: {
                            nRowsSelected: '{0} filas(s) seleccionadas',
                            searchPlaceholder: 'Buscar'
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay resultados que mostrar',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            }
                        }
                    }}
                    // other props
                    title="Productos relacionados"
                    columns={[
                        { title: "Número de serie", field: "serial_number" },
                        { title: "Modelo", field: "model" },
                        { title: "Producto", field: "product" },
                        { title: "Ubicación", field: "location" },
                        { title: "Cliente final", field: "client" },
                        { title: "Garantía", field: "warranty_status" }

                    ]}
                    options={{
                        pageSize: 5,
                        headerStyle: {
                            backgroundColor: '#245c80',
                            color: '#FFF'
                        },
                        actionsColumnIndex: -1
                    }}
                    data={data}
                />
            </div>
        </div>
    )
}

export default RelatedProducts
