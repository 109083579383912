import React, { useState, useEffect, useRef } from 'react';
import PackingList from './packingList';
import Desglose from './desglose';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Axios from 'axios';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Cookies from 'universal-cookie'


const Orden = ({ match, host }) => {
    const [active, setActive] = useState({ packingList: false, desglose: false });
    const [data, setData] = useState([]);
    const [value, setValue] = useState(0);
    const [guias, setGuias] = useState([]);
    const cookie = new Cookies();
    const [apiKey] = useState(cookie.get('token'));
    const desgloseTable = useRef(null);
    const packingListTable = useRef(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const avatarStyle = {
        background: '#245c80'
    }


    useEffect(() => {
        if (active.desglose) {
            setTimeout(() => {
                desgloseTable.current.scrollIntoView({ 'behaviour': 'smooth' });
            }, 200);
        }

    }, [active])

    useEffect(() => {
        setActive({ packingList: true, desglose: false });
        const endpoint = `${host}/orden`;
        const json = { "operation": "detalles", "api_key": apiKey, "order_id": match.params.order_id };
        Axios.post(endpoint, json)
            .then(res => {
                setData(res.data.detalles);
                setGuias(res.data.guias);
            })
    }, [apiKey, match.params.order_id, host])


    return (
        <div>

            {data.map((item, index) =>
                <div key={index}>
                    <Paper>
                        <h3 className="p-3">Orden #{match.params.order_id}</h3>
                        <div className="row mb-3">
                            <div className="col-12 col-md-6">
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">person</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Nombre" secondary={item.name} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">bar_chart</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Nivel" secondary={item.level} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">alternate_email</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Email" secondary={item.email} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">call</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Teléfono" secondary={item.telephone} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">local_shipping</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        {item.shipping_code === "xshippingpro.xshippingpro4" &&
                                            <ListItemText primary="Dirección de entrega" secondary="El distribuidor se hará cargo del envío" />
                                        }
                                        {item.shipping_code !== "xshippingpro.xshippingpro4" &&
                                            <ListItemText primary="Dirección de entrega" secondary={item.shipping_name + ', ' + item.shipping_address + ', ' + item.shipping_address2 + ', Cp. ' + item.shipping_postcode + ', ' + item.shipping_city + ', ' + item.shipping_state} />
                                        }

                                    </ListItem>
                                </List>
                            </div>
                            <div className="col-12 col-md-6">
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">date_range</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Fecha agregado" secondary={item.date_added} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">credit_card</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Método de pago" secondary={item.payment_method} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">history</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Estado" secondary={item.status} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">location_on</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Guías" secondary={
                                            guias.map((item) => (
                                                <p>{item.tracking_number}</p>
                                            ))
                                        } />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={avatarStyle}>
                                                <span className="material-icons">history</span>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Facturas" secondary={
                                            <>
                                                <span>{item.folio_factura}</span>
                                                <span>{item.id_fact}</span>
                                            </>

                                        } />
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </Paper>

                    <Paper className="mb-3">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Lista de empaque" onClick={() => {
                                setActive({ packingList: true, desglose: false });

                            }} />
                            <Tab label="Desglose" onClick={() => {
                                setActive({ packingList: false, desglose: true });
                            }} />
                        </Tabs>
                    </Paper>

                    {active.packingList &&
                        <div ref={packingListTable} >
                            <PackingList orderId={match.params.order_id} apiKey={cookie.get('token')} order_status_id={item.order_status_id} comments={item.comment} customer_id={item.customer_id} customer_group_id={item.customer_group_id} email={item.email} />
                        </div>
                    }

                    {active.desglose &&
                        <div ref={desgloseTable}>
                            <Desglose orderId={match.params.order_id} apiKey={cookie.get('token')} />
                        </div>
                    }
                </div>

            )}



        </div>

    )
}
export default Orden