const mercadolibreStoreOrderReducer = (state = { "data": "none" }, action) => {

    switch (action.type) {
        case 'SET_STORE_ORDER_DATA':
            state = action.payload;
        /* falls through */
        default:
            return state;
    }
}
export default mercadolibreStoreOrderReducer;